<template>
	<section class="">
		<div class="md:grid grid-cols-[8fr,4fr] min-h-screen">
			<div class="bg-gray-100 p-4 md:order-2">
				<div class="md:sticky md:top-24 md:min-h-[86vh] flex flex-col justify-between">
					<div>
						<h2 class="text-base font-medium sm:text-3xl mb-4">Recipient Info</h2>

						<div class="mb-12">
							<p>Who are you sending these to?</p>
							<user-search :focus-on-load="true" @selected="selectRecipient" />
						</div>
					</div>

					<div class="mt-8 w-full">
						<h2 class="text-base font-medium sm:text-3xl mb-4">Transfer Summary</h2>
						<div class="rounded-xl bg-white p-4 mb-8">
							<p>Number of Cards: {{ basketStore.numberOfCards }}</p>

							<div class="flex items-center my-4">
								<input
									id="sendRewardMiles"
									type="checkbox"
									class="form-checkbox h-5 w-5 text-blue-600"
									v-model="basketStore.isMilesTransferEnabled"
								/>
								<label for="sendRewardMiles" class="ml-2 text-gray-700">Send Reward Miles</label>
							</div>

							<div v-if="basketStore.isMilesTransferEnabled">
								<label for="milesToTransfer">How many miles would you like to send?</label>
								<div class="mt-1 relative rounded-md shadow-sm">
									<input
										id="milesToTransfer"
										type="text"
										placeholder="Amount"
										v-model="basketStore.milesToTransfer.qty"
										class="block w-full pr-12 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
									/>
									<div class="absolute inset-y-0 right-1 flex items-center">
										<label for="price" class="sr-only">{{ $token }}</label>
										<span class="text-black sm:text-md p-2 font-bold bg-white">{{ $token }}</span>
									</div>
								</div>
								<div class="flex justify-between">
									<div class="text-xs text-left mt-2">
										<p class="text-gray-500">Available miles: {{ $format(userMilesTotal) }}</p>
									</div>
									<div v-if="basketStore.milesToTransfer.qty > 0" class="text-xs text-right mt-2">
										<div v-if="transactionFee > 0">
											<div>
												<span>
													<span v-if="basketStore.recipient?.username"
														>@{{ basketStore.recipient?.username }} receives:</span
													>
													<span v-else>They receive: </span>
													<span
														>{{ $format(basketStore.milesToTransfer.qty) }}
														{{ $token }}</span
													></span
												>
											</div>
											<div class="">
												Transfer Fee:
												<span class="">{{ $format(transactionFee) }} {{ $token }}</span>
											</div>
											<div>
												<span>
													<span>You pay: </span>
													<span
														>{{
															$format(
																Number(basketStore.milesToTransfer.qty) +
																	Number(transactionFee),
															)
														}}
														{{ $token }}</span
													></span
												>
											</div>
										</div>
										<div v-else>
											<div>
												<span>
													<span v-if="basketStore.recipient?.username"
														>@{{ basketStore.recipient?.username }} receives:</span
													>
													<span v-else>They receive: </span>
													<span
														>{{ basketStore.milesToTransfer.qty }} {{ $token }}</span
													></span
												>
											</div>
										</div>
									</div>
								</div>

								<p class="text-sm mb-4 sm:mb-0 text-red-600 mt-2">
									<span v-if="!basketStore.isMilesQtyValid && basketStore.milesToTransfer.qty > 0"
										>Insufficient miles</span
									>
									<span
										v-if="
											isNaN(basketStore.milesToTransfer.qty) ||
											basketStore.milesToTransfer.qty < 0
										"
										>Must be a valid number</span
									>
								</p>
							</div>
						</div>

						<div class="flex justify-between space-x-4">
							<button
								@click="cancelBulkTransfer"
								class="btn shadow-none hover:bg-gray-200 text-lg disabled:bg-gray-200 disabled:cursor-not-allowed grow"
							>
								Cancel
							</button>

							<button
								@click="transfer"
								class="btn-primary shadow-none text-lg disabled:bg-gray-200 disabled:cursor-not-allowed grow"
								:disabled="!basketStore.isValidToTransfer"
							>
								Transfer
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="p-4">
				<h2 class="text-3xl mb-8">Transfer details</h2>

				<template v-for="item of basketStore.basket">
					<BasketAsset
						v-if="item.asset"
						:item="(item as BasketItem)"
						@remove="basketStore.removeItem(item.asset.asset_identifier)"
					/>
				</template>

				<div v-if="basketStore.basket.length === 0">
					<p class="mb-4">No items to bulk transfer. You can add items from your account collection.</p>
					<router-link to="/account" class="btn-primary hover:bg-sky-600">Goto to your account</router-link>
				</div>
			</div>
		</div>

		<modal v-if="transferState.isModalOpen">
			<template #default>
				<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
					<div v-if="!transferState.txComplete">
						<div class="p-6 border-b flex justify-between">
							<p class="text-2xl font-bold">Are you Sure?</p>
							<button
								v-if="!transferState.txInProgress"
								@click.stop="close(false)"
								class="text-gray-500 absolute top-4 right-4 text-xl"
							>
								<i class="fas fa-close"></i>
							</button>
						</div>

						<div class="p-6 pb-0">
							<div class="space-y-6 sm:space-y-0 sm:flex justify-between">
								<div class="grow">
									<p class="font-bold">Recipient:</p>
									<p>@{{ basketStore.recipient?.username }}</p>
								</div>

								<div class="grow">
									<div v-if="transactionFee > 0">
										<div class="flex">
											<div class="font-bold">They Receive:</div>
											<div v-if="basketStore.isMilesTransferEnabled">
												<div class="ml-2">
													{{ $format(basketStore.milesToTransfer.qty) }} {{ $token }}
												</div>
											</div>
										</div>
										<div class="text-gray-500 text-sm">
											Transfer Fee:
											{{ $format(transactionFee) }} {{ $token }}
										</div>

										<div class="text-gray-500 text-sm">
											Total:
											{{
												$format(
													Number(basketStore.milesToTransfer.qty) + Number(transactionFee),
												)
											}}
											{{ $token }}
										</div>
									</div>
									<div v-else>
										<p class="font-bold">Sending:</p>
										<div>{{ basketStore.milesToTransfer.qty }} {{ $token }}</div>
									</div>
								</div>
							</div>

							<div class="overflow-x-auto">
								<div class="flex space-x-2 mt-6 border-t pt-6">
									<div
										class="aspect-[3/4] w-16 text-right shrink-0"
										v-for="item in basketStore.basket"
									>
										<img
											:src="$cdnify(item.asset.image, 128)"
											:alt="item.asset.name"
											class="rounded-xl shadow-lg"
										/>
										<p class="text-xs mr-1">{{ item.quantity }}x</p>
									</div>
								</div>
							</div>

							<TotpInput
								v-if="transferState.require2fa"
								ref="totpInput"
								@totp-value="(code) => (transferState.pin2fa = code)"
								:disabled="transferState.txInProgress || transferState.txComplete"
								:is-totp="basketStore.sid === null"
								:errorMsg="transferState.error2fa"
							/>
						</div>

						<section class="p-6 grow flex flex-col bg-white">
							<div class="flex space-x-6"></div>
							<div
								v-if="transferState.txError"
								class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2"
							>
								<p class="font text-base">
									<span
										class="inline-flex justify-center items-center px-2 bg-red-600 text-xs text-white aspect-square rounded-full leading-none"
									>
										<i class="fa fa-exclamation"></i>
									</span>
									Transaction Error
								</p>
								<p class="text-base">
									{{ transferState.txError }}
								</p>
							</div>
						</section>
					</div>

					<div v-else>
						<div class="p-6 border-b">
							<p class="text-2xl font-bold">Success!</p>
						</div>
						<section class="p-6 flex flex-col items-center">
							<p>
								You have successfully transferred

								<span v-if="basketStore.basket.length > 0" class="font-bold">
									{{ basketStore.numberOfCards }}
									{{ basketStore.numberOfCards > 1 ? 'cards' : 'card' }}
								</span>

								<span v-if="basketStore.basket.length && basketStore.isMilesTransferEnabled">
									and
								</span>

								<span v-if="basketStore.isMilesTransferEnabled" class="font-bold">
									{{ basketStore.milesToTransfer.qty }} {{ $token }}
								</span>
								to user <span class="font-bold">@{{ basketStore.recipient?.username }}</span
								>.
							</p>
						</section>
					</div>

					<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
						<button
							v-if="!transferState.txInProgress && !transferState.txComplete"
							class="text-gray-500"
							@click.stop="close(false)"
						>
							{{ transferState.txError || transferState.txComplete ? 'Close' : 'Cancel' }}
						</button>

						<button
							v-if="!transferState.txComplete"
							@click.stop="transfer"
							class="btn-primary-lg enabled:bg-sky-600 enabled:hover:bg-sky-700 text-white space-x-1"
							:disabled="
								!basketStore.isValidToTransfer ||
								transferState.txInProgress ||
								(transferState.require2fa && transferState.pin2fa === '')
							"
						>
							<span v-if="!transferState.txInProgress">Transfer</span>
							<span v-else>
								<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
								Transferring...
							</span>
						</button>

						<button v-if="transferState.txComplete" @click="close(true)" class="btn-primary-lg">
							Done
						</button>
					</div>
				</div>
			</template>
		</modal>
	</section>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import { BasketItem, useBulkTransferStore } from '@/stores/BulkTransferStore'
import { useMarketStore } from '@/stores/MarketStore'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import UserSearch from '@/components/widgets/UserSearch.vue'
import Modal from '@/components/Modal.vue'
import { useAccountStore } from '@/stores/AccountStore'
import BasketAsset from '@/components/BulkTransfer/BasketAsset.vue'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { humanReadablePrice } from '@/util/currencyFormat'
import TotpInput from '@/components/TotpInput.vue'
import { defaultFeePercentage } from '@/stores/MarketStore'

interface SearchResult {
	username: string
	objectID: string
}

const totpInput = ref(null)
const router = useRouter()
const accountStore = useAccountStore()
const basketStore = useBulkTransferStore()
const marketStore = useMarketStore()
const newWalletStore = useNewWalletStore()
const transferState = ref({
	isModalOpen: false,
	txComplete: false,
	txInProgress: false,
	txError: null,
	tx: null,
	validBasketItemQty: true,
	require2fa: false,
	pin2fa: '',
	error2fa: null,
})

onBeforeMount(async () => {
	await loadUserCollection()
	newWalletStore.pollBalance()
})

onBeforeRouteLeave(() => {
	basketStore.toggleBasketMode(false)
})

async function loadUserCollection(force: boolean = false) {
	return Promise.all([marketStore.load(force), accountStore.load(force)])
}

function selectRecipient(user: SearchResult | null) {
	// user search result "objectID" prop is this string format:
	// "App\Models\BlokpaxUser::uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuuuuu"
	// we only need the last part of the string

	const userId = user?.objectID.split('::')[1]
	basketStore.recipient = user ? { username: user?.username, id: userId } : null
	basketStore.nonce = null
}

function close(isSuccess = false) {
	if (isSuccess) {
		basketStore.resetBasket()
		router.push({ name: 'account' })
	}
	transferState.value.isModalOpen = false
	transferState.value.require2fa = false
	transferState.value.txError = null
}

async function transfer() {
	if (!transferState.value.isModalOpen) {
		transferState.value.isModalOpen = true
		return
	}

	transferState.value.txError = null
	transferState.value.txInProgress = true

	try {
		if (transferState.value.require2fa && transferState.value.pin2fa) {
			await basketStore.confirm2fa(transferState.value.pin2fa)
		}

		// if( basketStore.milesToTransfer.qty > 0){
		// 	basketStore.milesToTransfer.qty = Number(basketStore.milesToTransfer.qty) + Number(transactionFee.value)
		// }

		const res = await basketStore.transfer(transactionFee.value)

		if (res.success) {
			transferState.value.txComplete = true
		} else if (res.code === 403) {
			console.log('BULK_TRANSFER_SUCCESS: FALSE!! 2fa required')
			if (transferState.value.require2fa) {
				transferState.value.error2fa = res.message
				totpInput.value?.clearInputs()
			}

			if (res?.sid) {
				basketStore.needs2fa(res.sid)
			}
			transferState.value.txInProgress = false
			transferState.value.require2fa = true
		} else {
			console.log('BULK_TRANSFER_SUCCESS: FALSE!')
			transferState.value.txError = res.message
			transferState.value.txInProgress = false
		}
	} catch (e) {
		console.log('BULK_TRANSFER_CATCH')
		console.error(e)
		transferState.value.tx = e
		transferState.value.txError = e.message
		transferState.value.txInProgress = false
	}
}

const transactionFee = computed(() => {
	const amount = basketStore.milesToTransfer.qty
	const feePercentage = defaultFeePercentage

	if (feePercentage == 0 || amount <= 25) {
		return 0
	}

	const feeCalculation = Math.round(amount * (1 + feePercentage / 10000))

	const fee = feeCalculation - amount

	return fee < 1 ? 1 : fee
})

function cancelBulkTransfer() {
	basketStore.toggleBasketMode(false, true)
	router.push({ name: 'account' })
}

const userMilesTotal = computed(() => {
	const amount = newWalletStore.balances.reduce(function (bnBalance, bal) {
		if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
			return bnBalance + BigInt(bal.available.toString())
		}
		return bnBalance
	}, BigInt(0))
	return humanReadablePrice(amount.toString())
})
const userMilesTransferrable = computed(() => {
	const amount = newWalletStore.balances.reduce(function (bnBalance, bal) {
		if (bal.slug == 'bpx') {
			return bnBalance + BigInt(bal.available.toString())
		}
		return bnBalance
	}, BigInt(0))
	return humanReadablePrice(amount.toString())
})
</script>
