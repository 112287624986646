<template>
	<section class="bpmp-storefront-collection">
		<header class="bpmp-storefront-collection__header">
			<!-- START HEADER -->
			<div class="my-2 flex space-x-2 md:space-x-4 items-stretch">
				<button class="bpmp-storefront-collection__filter-toggle" @click.prevent="toggleFilters">
					<i class="fa-solid fa-bars-filter"></i>
					<span class="hidden md:inline-block">Filter</span>
				</button>

				<div class="bpmp-storefront-collection__search">
					<i class="fa-solid fa-magnifying-glass flex-grow-0 flex-shrink-0"></i>
					<input
						type="text"
						placeholder="Search by name or trait"
						v-model="state.tmpAssetQuery"
						@keyup.enter.prevent="executeSearch"
						class="bpmp-storefront-collection__search-input"
					/>
					<button v-show="state.assetQuery.length" @click="clearSearch(true)">
						<i class="fa fa-times" />
					</button>
				</div>

				<div class="relative">
					<button
						class="shadow h-full bg-white border border-gray-300 rounded-xl px-4 py-2 font-medium leading-none"
						@click.stop="state.showSortOptions = !state.showSortOptions"
					>
						<span class="hidden md:flex items-center justify-center space-x-2">
							<p>{{ currentSort.label }}</p>
							<p>
								<i class="fa-solid fa-chevron-down"></i>
							</p>
						</span>
						<span class="md:hidden">
							<i class="fa-solid fa-arrow-up-arrow-down"></i>
						</span>
					</button>
					<div class="bpmp-storefront-collection__sort" v-cloak v-show="state.showSortOptions">
						<div class="flex justify-between m-4 sm:hidden">
							<h2 class="font-semibold text-xl flex-grow text-left">Sort</h2>
							<button @click.prevent="toggleFilters">
								<i class="fa-solid fa-sharp fa-times text-xl" />
							</button>
						</div>
						<div
							v-for="sortOption in sortOptions"
							:key="`sort-${sortOption.val}`"
							class="whitespace-nowrap px-4 py-3 hover:bg-gray-100/80 rounded-lg cursor-pointer flex justify-between items-center space-x-2"
							@click="updateSort(sortOption.val)"
						>
							<p>{{ sortOption.label }}</p>
							<p v-cloak v-show="state.sortBy == sortOption.val">
								<i class="fa-regular fa-check text-xl" />
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- END HEADER -->
		</header>
		<aside
			class="bpmp-storefront-collection__aside"
			:class="isSticky ? 'sm:sticky sm:top-[calc(84px+1rem)] sm:h-[calc(100vh-115px)] sm:overflow-auto' : ''"
			v-show="state.showFilters"
		>
			<!-- START ASIDE -->
			<StorefrontFilters
				class="max-sm:h-full max-sm:flex max-sm:flex-col"
				v-cloak
				:show-collection-filters="true"
				@close="toggleFilters"
				@done="toggleFilters"
				@clear="() => storeStore.clearFilters() || updateFilterParams()"
				@clear-all-filters="handleClearAllFilters"
				@filtersUpdated="updateFilterParams"
			/>
			<!-- END ASIDE -->
		</aside>
		<main class="bpmp-storefront-collection__main">
			<!-- START MAIN -->
			<filter-summary
				:query="state.assetQuery"
				:filters="storeStore.state.activeFilters"
				@clearQuery="clearSearch"
				@removeFilter="removeAndUpdateFilters"
				@clearAllFilters="
					() => {
						storeStore.clearFilters()
						updateFilterParams()
					}
				"
			/>

			<p class="mt-4" v-if="state.pageInfo?.total === 0">No Results</p>
			<grid
				v-else
				class="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 flex-grow"
				data-key="id"
				:pageProvider="(state.pageProvider as PageProvider)"
				:pageProviderDebounceTime="5"
				:pageSize="Math.min(24, state.pageInfo?.total ?? 24)"
				:length="state.pageInfo?.total ?? 100"
				:get-key="(item: InternalItem|Asset|any) => `${item.storefront_id}:${item.id}`"
			>
				<template #probe>
					<loading-tile />
				</template>
				<template #placeholder="{ style }">
					<loading-tile :style="style" />
				</template>
				<template #default="{ item, index, style }">
					<storefront-asset :asset="item" :style="style" :slug="storeStore.state.storefront.slug" />
				</template>
			</grid>
			<!-- END MAIN -->
		</main>
	</section>
</template>
<script lang="ts" setup>
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { AssetFilter, AssetSearchPagination } from '@/types/Storefront'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { InternalItem, PageProvider } from 'vue-virtual-scroll-grid'
import Grid from 'vue-virtual-scroll-grid'

import StorefrontFilters from '@/components/StorefrontFilters.vue'
import FilterSummary from '@/components/FilterSummary.vue'
import StorefrontAsset from '@/components/StorefrontAsset.vue'
import LoadingTile from '@/components/LoadingTile.vue'
import { Asset } from '@/types/Asset'

const router = useRouter()
const route = useRoute()
const state = ref({
	showFilters: false,
	showSortOptions: false,
	tmpAssetQuery: '',
	assetQuery: '',
	activeFilters: {} as any,
	sortBy: 'price_asc',
	pageInfo: {} as AssetSearchPagination,
	filterParams: {} as AssetFilter,
	pageProvider: null as Function,
})

const storeStore = useStorefrontStore()
const sortOptions = [
	{ label: 'Name A to Z', val: 'name_asc' },
	{ label: 'Price low to high', val: 'price_asc' },
	{ label: 'Price high to low', val: 'price_desc' },
	{ label: 'Recently listed', val: 'listed_on_desc' },
	{ label: 'Oldest listings', val: 'listed_on_asc' },
	{ label: 'Listings Ending First', val: 'listed_ending_asc' },
	{ label: 'Listings Ending Last', val: 'listed_ending_desc' },
]

const whiteList = {
	Auction: '',
	Listed: '',
	'Sold By Blokpax': '',
	...storeStore.state.metadata,
}

const currentSort = computed(() => {
	return sortOptions.find((o) => o.val == state.value.sortBy)
})

const isSticky = ref(false)

onBeforeMount(() => {
	updateFilterParams()
	// data massaging
	// (route.query) object will make values strings if there is only one value.
	// we need the value to always be an array for other components to continue working
	if (Object.entries(storeStore.state.activeFilters).length === 0 && Object.entries(route.query).length > 0) {
		const activeFiltersFromUrlQuery = Object.entries(route.query).reduce((objDataMassaged, keyValPair) => {
			for (const [key, value] of Object.entries(whiteList)) {
				if (keyValPair[0] == key) {
					objDataMassaged[keyValPair[0]] = Array.isArray(keyValPair[1]) ? [...keyValPair[1]] : [keyValPair[1]]
				}
			}

			return objDataMassaged
		}, {})
		storeStore.state.activeFilters = activeFiltersFromUrlQuery
	}
})

onMounted(async () => {
	// default the filters to open when the component mounts
	// if the user is on a tailwind md or bigger screen
	state.value.showFilters = window.innerWidth > 768

	updateFilterParams()

	const preContent = document.querySelector('.bpmp-storefront-collection__header') // this class is in @/Components/Storefront.vue

	const obsPreContent = new IntersectionObserver(
		([entry]) => {
			// console.log(entry.isIntersecting)
			entry.isIntersecting ? (isSticky.value = false) : (isSticky.value = true)
		},
		{ threshold: 0.1, rootMargin: '-100px' },
	)

	obsPreContent.observe(preContent)
})

function updateSort(sort: string) {
	state.value.sortBy = sort
	state.value.showSortOptions = false
	updateFilterParams()
}

function updateFilterParams() {
	state.value.pageInfo = null
	state.value.filterParams = {
		query: state.value.assetQuery,
		filters: storeStore.state.activeFilters,
		sort: state.value.sortBy,
	}

	// if active filters is not an empty object,
	// replace url state add it to url as query strings
	if (Object.entries(storeStore.state.activeFilters).length > 0) {
		const urlWithQueries = router.resolve({ query: storeStore.state.activeFilters })
		history.pushState(history.state, null, urlWithQueries.fullPath)
	}

	state.value.pageProvider = getPageProvider()
}

function getPageProvider(): PageProvider {
	return async (pgNumber, pgLimit) => {
		const result = await storeStore.loadStoreAssets(
			storeStore.state.storefront.slug,
			pgNumber,
			pgLimit,
			state.value.filterParams,
		)

		state.value.pageInfo = result.pagination
		return result.assets
	}
}

function clearSearch(withInput: boolean = false) {
	state.value.assetQuery = ''

	if (withInput) {
		state.value.tmpAssetQuery = ''
	}

	updateFilterParams()
}

function toggleFilters() {
	state.value.showFilters = !state.value.showFilters
}

function removeAndUpdateFilters(filter: string, value: string) {
	storeStore.removeFilter(filter, value)
	updateFilterParams()
}

function executeSearch() {
	state.value.assetQuery = state.value.tmpAssetQuery
	updateFilterParams()
}

function handleClearAllFilters() {
	storeStore.clearFilters()
	updateFilterParams()
	toggleFilters()
}
</script>

<style scoped>
@tailwind components;
@layer components {
	.bpmp-storefront-collection {
		@apply w-full
      flex
      flex-row
      flex-wrap
      items-start;
	}

	.bpmp-storefront-collection__header {
		@apply w-full
      p-4;
		/* background-color: red; */
	}

	.bpmp-storefront-collection__aside {
		@apply max-sm:fixed
      max-sm:z-50
      max-sm:bottom-0
      max-sm:bg-white
      max-sm:h-[calc(100vh-72px)]
      max-sm:w-full
      flex-shrink-0
      p-4
      sm:w-[20rem];
	}

	.bpmp-storefront-collection__main {
		@apply flex-grow
      flex-shrink
      p-4
      sm:w-[calc(100%-20rem)]
      self-stretch;
		/* background-color: yellow; */
	}

	.bpmp-storefront-collection__filter-toggle {
		@apply bg-white
      shadow
      px-4
      md:py-2
      aspect-square
      md:aspect-auto
      border
    border-gray-300
      rounded-xl
      space-x-2
      font-medium;
	}

	.bpmp-storefront-collection__search {
		@apply bg-white
    border-gray-300
    text-gray-600
      shadow
      border
      rounded-xl
      py-1
      px-4
      flex
      flex-shrink
      flex-grow
      items-center
      focus-within:ring-1
    focus-within:ring-gray-600;
	}

	.bpmp-storefront-collection__search-input {
		@apply w-full
      border-none
      outline-none
      hover:outline-none
      focus:outline-none
      focus:ring-0
      flex-grow
      flex-shrink;
	}

	.bpmp-storefront-collection__sort {
		@apply fixed
      flex-shrink-0
      shadow-t-md
      border-t-2
    border-gray-300
      inset-0
      top-auto
      h-auto
    bg-gray-50
      px-4
      py-4
      z-50
      md:absolute
      md:z-20
      md:shadow-lg
      md:top-full
      md:bottom-auto
      md:right-0
      md:left-auto
    md:bg-white
      md:min-w-full
      md:border
    md:border-gray-300
      md:mt-2
      md:rounded-xl
      md:px-2
      md:py-2;
	}
	.bpmp-storefront-collection__ {
	}
}
</style>
