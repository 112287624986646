<template>
	<modal>
		<template v-if="!props.asset" #default> waiting </template>
		<template v-else #default>
			<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
				<div v-if="!state.transfer.txComplete">
					<div class="p-6 border-b flex justify-between">
						<p class="text-2xl font-bold">Redeem For Physical Item</p>
						<button
							@click.stop="close"
							class="text-gray-500 absolute top-4 right-4 text-xl"
							aria-label="Close redemption modal"
						>
							<i class="fas fa-close"></i>
						</button>
					</div>

					<section v-if="state.transfer.txInProgress" class="p-6">Submitting redemption request...</section>

					<section v-else>
						<div class="p-6">
							<div class="p-4 border bg-slate-100 rounded-lg flex items-center">
								<div class="mt-1 mr-4 flex h-16 w-16 shrink-0 border rounded-lg">
									<img
										:src="$cdnify(props.asset?.image, 128)"
										:alt="props.asset?.name"
										class="mx-auto"
									/>
								</div>
								<div>
									<p class="font-semibold">{{ props.asset.name }}</p>
									<p class="text-sm">{{ props.asset.storefront.name }}</p>
								</div>
							</div>
						</div>
					</section>
				</div>

				<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
					<button
						v-if="!state.transfer.txInProgress && !state.transfer.txComplete"
						class="text-gray-500"
						@click.stop="close"
					>
						Cancel
					</button>

					<button
						@click.stop="redeemAsset"
						class="btn-primary-lg text-white space-x-1"
						:disabled="state.transfer.txInProgress"
					>
						<span v-if="!state.transfer.txInProgress"> Redeem <i class="fa-solid fa-paper-plane" /></span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
						</span>
					</button>

					<button v-if="state.transfer.txComplete" @click="close" class="btn-primary-lg">Done</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, onUnmounted } from 'vue'
import { Asset } from '@/types/Asset'
import { TransferState } from '@/types/Http'
import { useRedemptionStore } from '@/stores/RedemptionStore'
import { useAuthStore } from '@/stores/AuthStore'
import { useRouter } from 'vue-router'
import { useBulkTransferStore } from '@/stores/BulkTransferStore'
import * as Sentry from '@sentry/vue'

const redemptionStore = useRedemptionStore()
const authStore = useAuthStore()
const basketStore = useBulkTransferStore()
const router = useRouter()

const props = defineProps<{
	asset?: Asset
}>()

const emit = defineEmits<{
	(event: 'close'): void
	(event: 'reload'): void
}>()

function close() {
	if (state.transfer.txError || state.transfer.txComplete) {
		emit('reload')
	} else {
		emit('close')
	}
}

const state = reactive<{
	transfer: TransferState
	showConfirmAddress: boolean
	showConfirmWarning: boolean
}>({
	transfer: {
		txInProgress: false,
		txComplete: false,
		txError: null,
		tx: null,
		errors: {} as any,
	},
	showConfirmAddress: false,
	showConfirmWarning: false,
})

onMounted(() => {
	if (authStore.user) {
		redemptionStore.redemptionInfo.user = {
			id: authStore.user.id,
			username: authStore.user.username,
			email: authStore.user.email,
		}
	}

	if (props.asset) {
		redemptionStore.redemptionInfo.assets.push({
			name: props.asset.name,
			asset_id: props.asset.asset_identifier,
			storefront_id: props.asset.storefront.id,
			quantity: 1,
		})
	}
})

onUnmounted(() => {
	redemptionStore.resetRedemptionAssets()
})

async function redeemAsset() {
	try {
		basketStore.addItem(props.asset.asset_identifier, 1, props.asset)
		router.push({ name: 'redemptions' })
	} catch (e) {
		Sentry.captureException(e)
	}
}
</script>
