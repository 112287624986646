<template>
	<DefaultActivity :activity="props.activity">
		<template #asset-details>
			<p v-if="activity?.redemption_id" class="whitespace-nowrap">ID: {{ activity?.redemption_id }}</p>
		</template>
		<template #price v-if="activity?.paid">
			Cost: {{ $format(humanReadablePrice(activity?.paid || 0)) }} {{ $token }}
		</template>
		<template #user>@{{ activtyUser(activity) }}</template>
	</DefaultActivity>
</template>

<script lang="ts" setup>
import { AssetActivity } from '@/types/Asset'
import DefaultActivity from './DefaultActivity.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

const props = withDefaults(
	defineProps<{
		activity?: AssetActivity
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

function activtyUser(activity: AssetActivity) {
	const { recipient, sender } = activity
	if (recipient) {
		return recipient.username
	} else if (sender) {
		return sender.username
	} else {
		return ''
	}
}
</script>
