<template>
	<section class="overflow-x-auto @container">
		<div
			class="grid grid-cols-2 @3xl:grid-cols-[auto,auto,auto,auto,auto] bg-gray-100 mt-2 divide-y divide-gray-200 border-t border-gray-200 border rounded-xl overflow-x-auto"
		>
			<div class="hidden col-span-full @3xl:grid grid-cols-subgrid p-2 gap-2 font-semibold text-gray-700">
				<div class="px-2">Event</div>
				<div class="px-2">Details</div>
				<div class="px-2">Price</div>
				<div class="px-2">Seller</div>
				<div class="px-2 flex justify-end">Date</div>
			</div>

			<component
				v-for="activity in activities"
				:key="activity.timestamp"
				:is="getComponent(activity.action)"
				:activity="activity"
				:show-asset="props.showAsset"
			/>
		</div>
	</section>
</template>

<script lang="ts" setup>
import { AssetActivity } from '@/types/Asset'
import RedemptionActivity from './ActivityTypes/RedemptionActivity.vue'
import DefaultActivity from './ActivityTypes/DefaultActivity.vue'
import TransferActivity from './ActivityTypes/TransferActivity.vue'
import AuctionOutbid from './ActivityTypes/AuctionOutbid.vue'
import {
	AUCTION_OUTBID,
	REDEMPTION,
	REDEMPTION_CANCELLED_RECEIVED,
	REDEMPTION_CANCELLED_SENT,
	REDEMPTION_RECEIVED,
	REDEMPTION_SENT,
	TRANSFER_RECEIVED,
	TRANSFER_SENT,
} from '@/types/Activity'

const props = withDefaults(
	defineProps<{
		activities: AssetActivity[]
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

const activityComponentMap = {
	[REDEMPTION]: RedemptionActivity,
	[REDEMPTION_SENT]: RedemptionActivity,
	[REDEMPTION_RECEIVED]: RedemptionActivity,
	[REDEMPTION_CANCELLED_SENT]: RedemptionActivity,
	[REDEMPTION_CANCELLED_RECEIVED]: RedemptionActivity,

	[TRANSFER_SENT]: TransferActivity,
	[TRANSFER_RECEIVED]: TransferActivity,

	[AUCTION_OUTBID]: AuctionOutbid,

	default: DefaultActivity,
}

const getComponent = (type) => {
	return activityComponentMap[type] || activityComponentMap['default']
}
</script>
