export const TRANSFER = 'transfer'
export const TRANSFER_RECEIVED = 'transfer-received'
export const TRANSFER_SENT = 'transfer-sent'
export const AUCTION_WON = 'auction-won'
export const AUCTION_SOLD = 'auction-sold'
export const AUCTION_OUTBID = 'auction-outbid'
export const AUCTION_BID = 'bid-created'
export const AUCTION_ENDED = 'auction-ended'
export const AUCTION_EXPIRED = 'auction-expired'
export const LISTING_PURCHASED = 'listing-purchased'
export const LISTING_CREATED = 'listing-created'
export const LISTING_CANCELLED = 'listing-cancelled'
export const LISTING_SOLD = 'listing-sold'
export const OFFER_ACCEPTED = 'offer-accepted'
export const REDEMPTION = 'redemption'
export const REDEMPTION_RECEIVED = 'redemption-received'
export const REDEMPTION_SENT = 'redemption-sent'

export const REDEMPTION_CANCELLED_RECEIVED = 'redemptioncancel-received'
export const REDEMPTION_CANCELLED_SENT = 'redemptioncancel-sent'
