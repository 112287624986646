<template>
	<section class="">
		<div class="flex flex-col items-center min-h-screen" v-if="state.isLoadingInvoice">
			<p class="my-8 text-xl">Loading Redemption Invoice...</p>
			<i class="text-4xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
		</div>

		<div class="md:grid grid-cols-[8fr,4fr] min-h-screen" v-else>
			<div class="bg-gray-100 p-4 md:order-2">
				<div class="md:sticky md:top-24 md:min-h-[86vh] flex flex-col justify-between">
					<div>
						<h2 class="text-base font-medium sm:text-3xl mb-4">Redemption Info</h2>

						<div class="flex flex-col mb-12">
							<template v-if="!isInvoiceView">
								<p class="mb-8">
									You are redeeming your token for a Real World Asset. Please enter your shipping
									address (no PO Boxes) to receive your item.
								</p>
								<form-input-address
									label="Shipping Information"
									:address="redemptionStore.redemptionInfo.shippingInfo"
									:transfer-state="state.transfer"
									@update-address="(address) => redemptionStore.updateShippingInfo(address)"
									@update-transfer-state="(transferState) => (state.transfer = transferState)"
								/>
							</template>
							<template v-else>
								<p class="mb-8">
									You are redeeming your token for a Real World Asset. Please confirm your address
									below before proceeding.
								</p>
								<div class="p-4 bg-white rounded-xl mb-8">
									<p>{{ redemptionStore.redemptionInfo.shippingInfo.shipping_name }}</p>
									<address>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line1 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line2"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line2 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line3"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line3 }}<br
										/></span>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.city_locality }},
											{{ redemptionStore.redemptionInfo.shippingInfo.state_region }}
											{{ redemptionStore.redemptionInfo.shippingInfo.zip_postal_code }}<br
										/></span>
										<span>{{ redemptionStore.redemptionInfo.shippingInfo.country }}<br /></span>

										<span>{{
											getMaskedPhone(redemptionStore.redemptionInfo.shippingInfo.phone)
										}}</span>
									</address>
								</div>
							</template>
						</div>
					</div>

					<div class="mt-8 w-full">
						<template v-if="redemptionStore.payment.invoiceData">
							<h2 class="text-base font-medium sm:text-3xl mb-4">Redemption Summary</h2>
							<div class="rounded-xl bg-white p-4 mb-8 grid grid-cols-2 gap-2">
								<p>
									{{ redemptionStore.payment.invoiceData?.invoice.assets.length }}
									{{
										redemptionStore.payment.invoiceData?.invoice.assets.length > 1
											? 'Items'
											: 'Item'
									}}
								</p>

								<p class="text-right">
									${{
										$format(
											redemptionStore.payment.invoiceData?.invoice.totals.handling / 100,
											true,
										)
									}}
								</p>

								<p>Shipping</p>
								<p class="text-right">
									${{
										$format(
											redemptionStore.payment.invoiceData?.invoice.totals.shipping / 100,
											true,
										)
									}}
								</p>

								<hr class="col-span-2" />

								<p>Subtotal</p>
								<p class="text-right">
									${{ $format(redemptionStore.payment.invoiceData?.invoice.subtotal / 100, true) }}
								</p>

								<template v-if="redemptionStore.payment.invoiceData?.invoice.discounts.length > 0">
									<template
										v-for="discount in redemptionStore.payment.invoiceData?.invoice.discounts"
									>
										<p class="text-green-600">{{ discount.name }}</p>
										<p class="text-right text-green-600">
											- ${{ $format((-1 * discount.total) / 100, true) }}
										</p>
									</template>
								</template>

								<p>Processing Fee</p>
								<p class="text-right">
									${{
										$format(
											redemptionStore.payment.invoiceData?.invoice.totals.processing / 100,
											true,
										)
									}}
								</p>

								<hr class="col-span-2" />

								<p>Total Cost:</p>
								<p class="text-right">
									${{ $format(redemptionStore.payment.invoiceData?.invoice.total / 100) }}
								</p>
							</div>
						</template>

						<div class="flex justify-between space-x-4">
							<button
								@click="cancelBulkTransfer"
								class="btn shadow-none hover:bg-gray-200 text-lg disabled:bg-gray-200 disabled:cursor-not-allowed grow"
							>
								Cancel
							</button>

							<button
								@click="startRedemption"
								class="btn-primary shadow-none text-lg disabled:bg-gray-200 disabled:cursor-not-allowed grow"
								:disabled="!isValidToRedeem"
							>
								{{ redemptionStore.payment.redemption_id ? 'Pay and Redeem' : 'Confirm' }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="p-4">
				<h2 class="text-3xl mb-8">Redeem for Real World Asset</h2>

				<template v-for="item of basketStore.redeemableAssets">
					<BasketAsset
						v-if="item.asset"
						:item="(item as BasketItem)"
						amountToLabel="Redeem"
						@remove="basketStore.removeItem(item.asset.asset_identifier)"
						:disableQty="redemptionStore.payment.redemption_id !== ''"
					/>
				</template>

				<div v-if="basketStore.basket.length === 0">
					<p class="mb-4">No items to Redeem. You can add items from your account collection.</p>
					<router-link to="/account" class="btn-primary hover:bg-sky-600">Goto to your account</router-link>
				</div>
			</div>
		</div>

		<modal v-if="state.isModalOpen">
			<template #default>
				<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
					<div v-if="!state.transfer.txComplete" class="overflow-auto">
						<div class="p-6 border-b flex justify-between">
							<p class="text-2xl font-bold">Redemption Summary</p>
							<button
								v-if="!state.transfer.txInProgress"
								@click.stop="close(false)"
								class="text-gray-500 absolute top-4 right-4 text-xl"
							>
								<i class="fas fa-close"></i>
							</button>
						</div>

						<div class="p-6 pb-0">
							<div class="space-y-6 sm:space-y-0 sm:grid grid-cols-2">
								<div>
									<p class="font-bold">Shipping To:</p>
									<p>{{ redemptionStore.redemptionInfo.shippingInfo.shipping_name }}</p>
									<address>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line1 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line2"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line2 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line3"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line3 }}<br
										/></span>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.city_locality }},
											{{ redemptionStore.redemptionInfo.shippingInfo.state_region }}
											{{ redemptionStore.redemptionInfo.shippingInfo.zip_postal_code }}<br
										/></span>
										<span>{{ redemptionStore.redemptionInfo.shippingInfo.country }}<br /></span>

										<span>{{
											getMaskedPhone(redemptionStore.redemptionInfo.shippingInfo.phone)
										}}</span>
									</address>
								</div>

								<div v-if="redemptionStore.payment.invoiceData">
									<div class="mb-8 grid grid-cols-2 gap-2">
										<template v-if="redemptionStore.payment.invoiceData?.public_id">
											<p>Redemption ID:</p>
											<p class="text-right">
												{{ redemptionStore.payment.invoiceData?.public_id }}
											</p>
										</template>

										<p>Quantity of Items:</p>
										<p class="text-right">
											{{ basketStore.numberOfCards }}
											{{ basketStore.numberOfCards > 1 ? 'Cards' : 'Card' }}
										</p>

										<p>Total Cost:</p>
										<p class="text-right">
											${{ $format(redemptionStore.payment.invoiceData?.invoice.total / 100) }}
										</p>
									</div>
								</div>
							</div>

							<p class="mt-6" v-if="!state.showConfirmWarning">
								Please confirm your shipping information carefully before proceeding. Once submitted,
								your {{ basketStore.redeemableAssets.length > 1 ? 'tokens' : 'token' }} will be sent to
								our Redemption Team for processing, and incorrect addresses may not be correctable
								before shipment.
							</p>

							<div class="overflow-x-auto">
								<div class="flex space-x-2 mt-6 border-t pt-6">
									<div
										class="aspect-[3/4] w-16 text-right shrink-0"
										v-for="item in basketStore.redeemableAssets"
									>
										<img
											:src="$cdnify(item.asset.image, 128)"
											:alt="item.asset.name"
											class="rounded-xl shadow-lg"
										/>
										<p class="text-xs mr-1">{{ item.quantity }}x</p>
									</div>
								</div>
							</div>

							<TotpInput
								v-if="state.require2fa"
								ref="totpInput"
								@totp-value="(code) => (state.pin2fa = code)"
								:disabled="state.transfer.txInProgress || state.transfer.txComplete"
								:is-totp="basketStore.sid === null"
								:errorMsg="state.error2fa"
							/>
						</div>

						<section class="p-6 grow flex flex-col bg-white">
							<div class="flex space-x-6"></div>
							<div
								v-if="state.transfer.txError"
								class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2"
							>
								<p class="font text-base">
									<span
										class="inline-flex justify-center items-center px-2 bg-red-600 text-xs text-white aspect-square rounded-full leading-none"
									>
										<i class="fa fa-exclamation"></i>
									</span>
									Transaction Error
								</p>
								<p class="text-base">
									{{ state.transfer.txError }}
								</p>
							</div>

							<div v-if="state.showConfirmWarning && !state.transfer.txComplete">
								<div class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										Warning
									</p>
									<p class="text-base">
										This action <strong class="uppercase">cannot</strong> be undone. Make sure your
										Shipping Address is correct and that you know you will not see your token in
										your collections anymore after before pressing <em>Redeem</em> below.
									</p>
								</div>
							</div>
						</section>
					</div>

					<div v-else>
						<div class="p-6 border-b">
							<p class="text-2xl font-bold">Success!</p>
						</div>
						<section class="p-6 flex flex-col items-center">
							<p>
								You have successfully started the redemption process for

								<span v-if="basketStore.redeemableAssets.length > 0" class="font-bold">
									{{ basketStore.redeemableAssets.length }}
									{{ basketStore.redeemableAssets.length > 1 ? 'cards' : 'card' }}.
								</span>
							</p>
						</section>
					</div>

					<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
						<button
							v-if="!state.transfer.txInProgress && !state.transfer.txComplete"
							class="text-gray-500"
							@click.stop="close(false)"
						>
							{{ state.transfer.txError || state.transfer.txComplete ? 'Close' : 'Cancel' }}
						</button>

						<button
							v-if="!state.transfer.txComplete"
							@click.stop="startRedemption"
							class="btn-primary-lg enabled:bg-sky-600 enabled:hover:bg-sky-700 text-white space-x-1"
							:disabled="
								!basketStore.isValidToRedeem ||
								state.transfer.txInProgress ||
								(state.require2fa && state.pin2fa === '')
							"
						>
							<span v-if="!state.transfer.txInProgress">{{
								redemptionStore.payment.redemption_id ? 'Pay and Redeem' : 'Continue to Payment'
							}}</span>
							<span v-else>
								<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
								Processing...
							</span>
						</button>

						<button v-if="state.transfer.txComplete" @click="close(true)" class="btn-primary-lg">
							Done
						</button>
					</div>
				</div>
			</template>
		</modal>
	</section>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue'
import Bugsnag from '@bugsnag/js'
import { BasketItem, useBulkTransferStore } from '@/stores/BulkTransferStore'
import { useMarketStore } from '@/stores/MarketStore'
import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import { useAccountStore } from '@/stores/AccountStore'
import BasketAsset from '@/components/BulkTransfer/BasketAsset.vue'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import TotpInput from '@/components/TotpInput.vue'

import FormInputAddress from '@/components/FormInputAddress.vue'
import { useRedemptionStore } from '@/stores/RedemptionStore'
import { TransferState } from '@/types/Http'
import { useAuthStore } from '@/stores/AuthStore'
import { Mask } from 'maska'
import { RedemptionInvoicePreview } from '@/types/Redemption'

const totpInput = ref(null)
const route = useRoute()
const router = useRouter()
const accountStore = useAccountStore()
const basketStore = useBulkTransferStore()
const marketStore = useMarketStore()
const newWalletStore = useNewWalletStore()
const redemptionStore = useRedemptionStore()
const authStore = useAuthStore()

const state = reactive<{
	transfer: TransferState
	isModalOpen: boolean
	showConfirmWarning: boolean
	require2fa: boolean
	pin2fa: string
	error2fa: string | null
	isLoadingInvoice: boolean
	invoicePreview: RedemptionInvoicePreview | null
}>({
	transfer: {
		txInProgress: false,
		txComplete: false,
		txError: null,
		tx: null,
		errors: {} as any,
	},
	isModalOpen: false,
	showConfirmWarning: false,
	require2fa: false,
	pin2fa: '',
	error2fa: null,
	isLoadingInvoice: false,
	invoicePreview: null,
})

onBeforeMount(async () => {
	await loadUserCollection()
	newWalletStore.pollBalance()

	if (authStore.user) {
		redemptionStore.redemptionInfo.user = {
			id: authStore.user.id,
			username: authStore.user.username,
			email: authStore.user.email,
		}
	}

	const { redemption_id } = route.query

	if (!redemption_id && basketStore.redeemableAssets.length > 0) {
		try {
			const res = await redemptionStore.getRedemptionPrice(basketStore.redeemableAssets)
			if (res.success) {
				redemptionStore.payment.invoiceData = {
					invoice: res.data.invoice,
				}
			}
		} catch (e) {
			console.error('Failed to get redemption price preview', e)
		}
	}
})

onMounted(async () => {
	const { redemption_id, acct, auth } = route.query

	if (redemption_id && acct && auth) {
		Bugsnag.leaveBreadcrumb('Loading existing redemption')
		state.isLoadingInvoice = true

		try {
			const res = await redemptionStore.getRedemptionInvoice(redemption_id as string)

			if (!res.success) {
				Bugsnag.notify(new Error('Failed to load redemption invoice'), function (evt) {
					evt.addMetadata({
						redemption_id,
						acct,
						auth,
						message: res.message,
					})
				})
				console.error(res.message)
				return
			}

			redemptionStore.payment.invoiceData = res.data
			console.log('invoice data', redemptionStore.payment.invoiceData)

			res.data.assets.forEach((asset) => {
				basketStore.addItem(asset.asset_id, asset.quantity, asset)
			})

			const address = extractAddress(res.data)
			redemptionStore.updateShippingInfo(address)

			redemptionStore.payment.redemption_id = redemption_id as string
			redemptionStore.payment.acct = acct as string
			redemptionStore.payment.auth = auth as string
		} catch (e) {
			Bugsnag.notify(e, function (event) {
				event.addMetadata({
					redemption_id,
					acct,
					auth,
				})
			})

			console.error(e)
		} finally {
			state.isLoadingInvoice = false
		}
	}
})

onBeforeRouteLeave(() => {
	basketStore.toggleBasketMode(false)
	if (redemptionStore.payment.invoiceData) {
		redemptionStore.payment.invoiceData = null
		redemptionStore.payment.redemption_id = ''
		redemptionStore.payment.acct = ''
		redemptionStore.payment.auth = ''
		basketStore.resetBasket()
	}
})

async function loadUserCollection(force: boolean = false) {
	return Promise.all([marketStore.load(force), accountStore.load(force)])
}

function close(isSuccess = false) {
	if (isSuccess) {
		basketStore.resetBasket()
		router.push({ name: 'account' })
	}
	state.isModalOpen = false
	state.require2fa = false
	state.showConfirmWarning = false
	state.transfer.txError = null
	state.transfer.tx = null
}

async function startRedemption() {
	if (!redemptionStore.payment.redemption_id) {
		redeemIntent()
	} else {
		await redeem()
	}
}

async function redeemIntent() {
	if (!state.isModalOpen) {
		state.isModalOpen = true
		return
	}

	if (!state.showConfirmWarning) {
		state.showConfirmWarning = true
		return
	}

	try {
		state.transfer.txInProgress = true
		state.transfer.txError = null
		state.transfer.tx = null

		if (state.require2fa && state.pin2fa) {
			await basketStore.confirm2fa(state.pin2fa)
		}

		const res = await redemptionStore.initiateRedemptionPayment(basketStore.redeemableAssets)

		if (res.success && res.redirect) {
			console.log('redirecting to', res.redirect)
			window.location.href = res.redirect
		} else if (res.code === 403) {
			if (state.require2fa) {
				state.error2fa = res.message
				totpInput?.value.clearInputs()
			}

			if (res?.sid) {
				basketStore.needs2fa(res.sid)
			}

			state.transfer.txInProgress = false
			state.require2fa = true
		} else {
			state.transfer.tx = res
			state.transfer.txError = res.message
			state.transfer.txInProgress = false
		}
	} catch (e) {
		Bugsnag.notify(e)
		console.error(e)
		state.transfer.tx = e
		state.transfer.txError = e.message
		state.transfer.txInProgress = false
	}
}

async function redeem() {
	if (!state.isModalOpen) {
		state.isModalOpen = true
		return
	}

	if (!state.showConfirmWarning) {
		state.showConfirmWarning = true
		return
	}

	state.transfer.txInProgress = true
	state.transfer.txError = null
	state.transfer.tx = null

	try {
		if (state.require2fa && state.pin2fa) {
			await basketStore.confirm2fa(state.pin2fa)
		}

		const res = await redemptionStore.redeemAssets()

		if (res.success) {
			state.transfer.txComplete = true
		} else if (res.code === 403) {
			if (state.require2fa) {
				state.error2fa = res.message
				totpInput?.value.clearInputs()
			}

			if (res?.sid) {
				basketStore.needs2fa(res.sid)
			}

			state.transfer.txInProgress = false
			state.require2fa = true
		} else {
			state.transfer.tx = res
			state.transfer.txError = res.message
			state.transfer.txInProgress = false
		}
	} catch (e) {
		console.error(e)
		state.transfer.tx = e
		state.transfer.txError = e.message
		state.transfer.txInProgress = false
	}
}

function cancelBulkTransfer() {
	basketStore.toggleBasketMode(false, true)
	router.push({ name: 'account' })
}

const isValidToRedeem = computed(() => {
	return (
		basketStore.redeemableAssets.length > 0 &&
		basketStore.isValidToRedeem &&
		redemptionStore.redemptionInfo.shippingInfo.shipping_name &&
		redemptionStore.redemptionInfo.shippingInfo.address_line1 &&
		redemptionStore.redemptionInfo.shippingInfo.city_locality &&
		redemptionStore.redemptionInfo.shippingInfo.state_region &&
		redemptionStore.redemptionInfo.shippingInfo.zip_postal_code &&
		!state.transfer.errors.zip_postal_code &&
		!state.transfer.errors.phone
	)
})

function extractAddress(data: any) {
	const {
		shipping_name,
		address_line1,
		address_line2,
		address_line3,
		city_locality,
		state_region,
		zip_postal_code,
		country,
		phone,
	} = data

	return {
		shipping_name,
		address_line1,
		address_line2,
		address_line3,
		city_locality,
		state_region,
		zip_postal_code,
		country,
		phone,
	}
}

const isInvoiceView = computed(() => {
	return (
		redemptionStore.payment.redemption_id &&
		redemptionStore.payment.acct &&
		redemptionStore.payment.auth &&
		redemptionStore.payment.invoiceData
	)
})

function getMaskedPhone(phone: string) {
	const mask = new Mask({ mask: ['(###) ###-####', '+## ### ### ####', '+### ### ### ####'] })
	return mask.masked(phone)
}

const numberOfCards = computed(() => {
	return basketStore.redeemableAssets.reduce((acc, item) => acc + Number(item.quantity), 0)
})
</script>
