import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { RedemptionInfo, RedemptionPaymentCaptureData } from '@/types/Redemption'
import api from '@/util/api'
import { useAuthStore } from './AuthStore'
import { BasketItem } from './BulkTransferStore'
import { UserAddress } from '@/types/Forms'

export const useRedemptionStore = defineStore('redemption', () => {
	const redemptionInfo = reactive<RedemptionInfo>({
		assets: [],
		shippingInfo: {
			shipping_name: '',
			address_line1: '',
			address_line2: '',
			address_line3: '',
			city_locality: '',
			state_region: '',
			zip_postal_code: '',
			country: 'US',
			phone: '',
		},
	})

	loadShippingInfo()

	const payment = reactive<RedemptionPaymentCaptureData>({
		redemption_id: '',
		acct: '',
		auth: '',
		invoiceData: null,
	})

	async function redeemAssets(): Promise<{
		success: boolean
		message: string
		code: number | string | null
		sid?: string | null
	}> {
		return api.redeemAssetCapturePayment(payment)
	}

	function resetRedemptionAssets() {
		redemptionInfo.assets = []
	}

	async function initiateRedemptionPayment(
		assets?: BasketItem[],
		pin?: string,
	): Promise<{
		success: boolean
		message: string
		redirect: string
		code: number | string | null
		sid?: string | null
	}> {
		if (assets?.length) {
			redemptionInfo.assets = assets.map((asset) => ({
				storefront_id: asset.asset.storefront_id,
				asset_id: asset.asset_id,
				name: asset.asset.name,
				quantity: asset.quantity as number,
			}))
		}

		return await api.initiateRedemptionPayment(redemptionInfo, pin)
	}

	async function getRedemptionInvoice(
		redemptionId: string,
	): Promise<{ success: boolean; message?: string; data?: any }> {
		return await api.getRedemptionInvoice(redemptionId)
	}

	function updateShippingInfo(userShippingInfo: UserAddress) {
		redemptionInfo.shippingInfo = userShippingInfo
		try {
			localStorage.setItem('userShippingInfo', JSON.stringify(userShippingInfo))
		} catch (error) {
			console.error('Failed to store shipping info locally', error)
		}
	}

	function loadShippingInfo() {
		try {
			const userShippingInfoString = localStorage.getItem('userShippingInfo')
			if (userShippingInfoString) {
				const {
					shipping_name,
					address_line1,
					address_line2,
					address_line3,
					city_locality,
					state_region,
					zip_postal_code,
					country,
					phone,
				} = JSON.parse(userShippingInfoString)

				const shippingInfo: UserAddress = {
					shipping_name,
					address_line1,
					address_line2,
					address_line3,
					city_locality,
					state_region,
					zip_postal_code,
					country,
					phone,
				}

				redemptionInfo.shippingInfo = shippingInfo
			}
		} catch (error) {
			console.error('Failed to load shipping info from local storage')
		}
	}

	async function getRedemptionPrice(assets: BasketItem[]): Promise<{
		success: boolean
		message: string
		data: any
	}> {
		redemptionInfo.assets = assets.map((asset) => ({
			storefront_id: asset.asset.storefront_id,
			asset_id: asset.asset_id,
			name: asset.asset.name,
			quantity: asset.quantity as number,
		}))

		return await api.getRedemptionPricePreview(redemptionInfo)
	}

	return {
		redemptionInfo,
		payment,
		redeemAssets,
		resetRedemptionAssets,
		updateShippingInfo,
		initiateRedemptionPayment,
		getRedemptionInvoice,
		getRedemptionPrice,
	}
})
