<template>
	<input
		type="tel"
		v-model="phoneNumber"
		v-maska="{
			mask: ['(###) ###-####', '+## ### ### ####', '+### ### ### ####'],
		}"
		@maska="onMaska"
		class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
		aria-label="Phone Number"
		placeholder="Phone Number"
	/>
</template>

<script setup lang="ts">
import { ref, defineExpose, onBeforeMount } from 'vue'
import { vMaska } from 'maska/vue'
import { MaskaDetail } from 'maska'

const phoneNumber = ref('')
const phoneNumberUnmasked = ref('')
const isMaskCompleted = ref(false)

const props = defineProps<{
	modelValue: string
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', val: string): void
}>()

onBeforeMount(() => {
	phoneNumber.value = props.modelValue
})

function onMaska(e: CustomEvent<MaskaDetail>) {
	phoneNumberUnmasked.value = e.detail.unmasked
	isMaskCompleted.value = e.detail.completed

	emit('update:modelValue', phoneNumberUnmasked.value)
}

defineExpose({
	phoneNumber,
	phoneNumberUnmasked,
	isMaskCompleted,
})
</script>
