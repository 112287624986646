<template>
	<section v-if="state.loading" class="flex justify-center items-center">
		<i class="text-5xl fa-regular fa-spinner-third fa-spin"></i>
	</section>
	<section ref="activityTable" v-else>
		<div class="flex justify-between items-baseline md:mx-0">
			<h2 class="text-lg font-medium text-gray-900 inline-flex">
				<ArrowsUpDownIcon class="stroke-2 mr-2 h-6 w-6 text-gray-900 -rotate-180" />
				Item Activity
			</h2>
			<div
				v-if="state.pagination?.last_page && state.pagination?.last_page > 1"
				class="text-right text-gray-400 text-sm"
			>
				Viewing page {{ state.pagination?.current_page }} of {{ state.pagination?.last_page }}
			</div>
		</div>

		<section>
			<ActivityRenderer :activities="state.data" :showAsset="props.showAsset" />
		</section>

		<div class="mt-1">
			<div
				v-if="state.pagination?.last_page && state.pagination?.last_page > 1"
				class="text-right text-gray-400 text-sm mx-4"
			>
				Viewing page {{ state.pagination?.current_page }} of {{ state.pagination?.last_page }}
			</div>
		</div>

		<paginator
			v-if="state.pagination?.current_page && state.pagination?.last_page > 1"
			class="my-6"
			:showPages="5"
			:pages="state.pagination?.last_page"
			:current="state.pagination?.current_page"
			@selectPage="updatePage"
		/>
	</section>
</template>
<script lang="ts" setup>
import sleep from '@/util/sleep'
import { onMounted, reactive, ref } from 'vue'
import { useMarketStore } from '@/stores/MarketStore'
import { useAuthStore } from '@/stores/AuthStore'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/outline'
import { AssetActivity } from '@/types/Asset'
import Paginator from '@/components/widgets/Paginator.vue'
import ActivityRenderer from '@/components/Activity/ActivityRenderer.vue'

const authStore = useAuthStore()
const marketStore = useMarketStore()

type ActivityPagination = {
	current_page: number
	from: number
	last_page: number
	links: {
		url: null | string
		label: string
		active: boolean
	}[]
	path: string
	per_page: number
	to: number
	total: number
}
type AssetActivityLoader = (page: number | null) => Promise<{
	data: AssetActivity[]
	meta: ActivityPagination
}>

const props = withDefaults(
	defineProps<{
		dataLoader: AssetActivityLoader
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

const state = reactive<{
	loading: boolean
	currentPage: number
	data: AssetActivity[]
	pagination: null | ActivityPagination
}>({
	loading: true,
	currentPage: 1,
	data: [],
	pagination: null,
})

const activityTable = ref(null)

onMounted(async () => {
	marketStore.load()
	await load()
})

async function load(page: number | null = null) {
	state.loading = true
	const { data, meta } = await props.dataLoader(page ?? state.currentPage)
	state.data = data
	state.pagination = meta
	state.loading = false
}

function updatePage(num: number) {
	const wait = sleep(0.75)
	state.loading = true
	load(num)
		.then(() => {
			state.currentPage = num
		})
		.then(() => {
			window.scrollTo({
				top: activityTable.value.offsetTop - 200,
				behavior: 'smooth',
			})
		})
		.then(wait)
		.then(() => (state.loading = false))
}

defineExpose({
	load,
})
</script>
