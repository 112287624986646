<template>
	<slot :pluralized="pluralized">
		{{ pluralized }}
	</slot>
</template>
<script lang="ts">
import { processSlotOutlet } from '@vue/compiler-core'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		amount: {
			required: true,
		},
		singular: {
			required: true,
		},
		plural: {
			required: true,
		},
	},

	setup(props) {
		const pluralized = computed(() => {
			if (props.amount == 1) return props.singular
			return props.plural
		})
		return {
			props,
			pluralized,
		}
	},
})
</script>
