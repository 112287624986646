interface AbbreviatedCodes {
	readonly name: string
	readonly abbreviation: string
}

export const usStates: AbbreviatedCodes[] = [
	{ name: 'Alabama', abbreviation: 'AL' },
	{ name: 'Alaska', abbreviation: 'AK' },
	{ name: 'American Samoa', abbreviation: 'AS' },
	{ name: 'Arizona', abbreviation: 'AZ' },
	{ name: 'Arkansas', abbreviation: 'AR' },
	{ name: 'California', abbreviation: 'CA' },
	{ name: 'Colorado', abbreviation: 'CO' },
	{ name: 'Connecticut', abbreviation: 'CT' },
	{ name: 'Delaware', abbreviation: 'DE' },
	{ name: 'District Of Columbia', abbreviation: 'DC' },
	{ name: 'Federated States Of Micronesia', abbreviation: 'FM' },
	{ name: 'Florida', abbreviation: 'FL' },
	{ name: 'Georgia', abbreviation: 'GA' },
	{ name: 'Guam', abbreviation: 'GU' },
	{ name: 'Hawaii', abbreviation: 'HI' },
	{ name: 'Idaho', abbreviation: 'ID' },
	{ name: 'Illinois', abbreviation: 'IL' },
	{ name: 'Indiana', abbreviation: 'IN' },
	{ name: 'Iowa', abbreviation: 'IA' },
	{ name: 'Kansas', abbreviation: 'KS' },
	{ name: 'Kentucky', abbreviation: 'KY' },
	{ name: 'Louisiana', abbreviation: 'LA' },
	{ name: 'Maine', abbreviation: 'ME' },
	{ name: 'Marshall Islands', abbreviation: 'MH' },
	{ name: 'Maryland', abbreviation: 'MD' },
	{ name: 'Massachusetts', abbreviation: 'MA' },
	{ name: 'Michigan', abbreviation: 'MI' },
	{ name: 'Minnesota', abbreviation: 'MN' },
	{ name: 'Mississippi', abbreviation: 'MS' },
	{ name: 'Missouri', abbreviation: 'MO' },
	{ name: 'Montana', abbreviation: 'MT' },
	{ name: 'Nebraska', abbreviation: 'NE' },
	{ name: 'Nevada', abbreviation: 'NV' },
	{ name: 'New Hampshire', abbreviation: 'NH' },
	{ name: 'New Jersey', abbreviation: 'NJ' },
	{ name: 'New Mexico', abbreviation: 'NM' },
	{ name: 'New York', abbreviation: 'NY' },
	{ name: 'North Carolina', abbreviation: 'NC' },
	{ name: 'North Dakota', abbreviation: 'ND' },
	{ name: 'Northern Mariana Islands', abbreviation: 'MP' },
	{ name: 'Ohio', abbreviation: 'OH' },
	{ name: 'Oklahoma', abbreviation: 'OK' },
	{ name: 'Oregon', abbreviation: 'OR' },
	{ name: 'Palau', abbreviation: 'PW' },
	{ name: 'Pennsylvania', abbreviation: 'PA' },
	{ name: 'Puerto Rico', abbreviation: 'PR' },
	{ name: 'Rhode Island', abbreviation: 'RI' },
	{ name: 'South Carolina', abbreviation: 'SC' },
	{ name: 'South Dakota', abbreviation: 'SD' },
	{ name: 'Tennessee', abbreviation: 'TN' },
	{ name: 'Texas', abbreviation: 'TX' },
	{ name: 'Utah', abbreviation: 'UT' },
	{ name: 'Vermont', abbreviation: 'VT' },
	{ name: 'Virgin Islands', abbreviation: 'VI' },
	{ name: 'Virginia', abbreviation: 'VA' },
	{ name: 'Washington', abbreviation: 'WA' },
	{ name: 'West Virginia', abbreviation: 'WV' },
	{ name: 'Wisconsin', abbreviation: 'WI' },
	{ name: 'Wyoming', abbreviation: 'WY' },
]

export const countries: AbbreviatedCodes[] = [
	{ name: 'Afghanistan', abbreviation: 'AF' },
	{ name: 'Albania', abbreviation: 'AL' },
	{ name: 'Algeria', abbreviation: 'DZ' },
	{ name: 'American Samoa', abbreviation: 'AS' },
	{ name: 'Andorra', abbreviation: 'AD' },
	{ name: 'Angola', abbreviation: 'AO' },
	{ name: 'Anguilla', abbreviation: 'AI' },
	{ name: 'Antigua and Barbuda', abbreviation: 'AG' },
	{ name: 'Argentina', abbreviation: 'AR' },
	{ name: 'Armenia', abbreviation: 'AM' },
	{ name: 'Aruba', abbreviation: 'AW' },
	{ name: 'Australia', abbreviation: 'AU' },
	{ name: 'Austria', abbreviation: 'AT' },
	{ name: 'Azerbaijan', abbreviation: 'AZ' },
	{ name: 'Bahamas', abbreviation: 'BS' },
	{ name: 'Bahrain', abbreviation: 'BH' },
	{ name: 'Bangladesh', abbreviation: 'BD' },
	{ name: 'Barbados', abbreviation: 'BB' },
	{ name: 'Belarus', abbreviation: 'BY' },
	{ name: 'Belgium', abbreviation: 'BE' },
	{ name: 'Belize', abbreviation: 'BZ' },
	{ name: 'Benin', abbreviation: 'BJ' },
	{ name: 'Bermuda', abbreviation: 'BM' },
	{ name: 'Bhutan', abbreviation: 'BT' },
	{ name: 'Bolivia', abbreviation: 'BO' },
	{ name: 'Bosnia and Herzegovina', abbreviation: 'BA' },
	{ name: 'Botswana', abbreviation: 'BW' },
	{ name: 'Brazil', abbreviation: 'BR' },
	{ name: 'British Virgin Islands', abbreviation: 'VG' },
	{ name: 'Brunei Darussalam', abbreviation: 'BN' },
	{ name: 'Bulgaria', abbreviation: 'BG' },
	{ name: 'Burkina Faso', abbreviation: 'BF' },
	{ name: 'Burundi', abbreviation: 'BI' },
	{ name: 'Cambodia', abbreviation: 'KH' },
	{ name: 'Cameroon', abbreviation: 'CM' },
	{ name: 'Canada', abbreviation: 'CA' },
	{ name: 'Cape Verde', abbreviation: 'CV' },
	{ name: 'Central African Republic', abbreviation: 'CF' },
	{ name: 'Chad', abbreviation: 'TD' },
	{ name: 'Chile', abbreviation: 'CL' },
	{ name: 'China', abbreviation: 'CN' },
	{ name: 'Hong Kong', abbreviation: 'HK' },
	{ name: 'Macao', abbreviation: 'MO' },
	{ name: 'Colombia', abbreviation: 'CO' },
	{ name: 'Comoros', abbreviation: 'KM' },
	{ name: 'Congo', abbreviation: 'CG' },
	{ name: 'Costa Rica', abbreviation: 'CR' },
	{ name: "Côte d'Ivoire", abbreviation: 'CI' },
	{ name: 'Croatia', abbreviation: 'HR' },
	{ name: 'Cuba', abbreviation: 'CU' },
	{ name: 'Cyprus', abbreviation: 'CY' },
	{ name: 'Czechia', abbreviation: 'CZ' },
	{ name: 'Denmark', abbreviation: 'DK' },
	{ name: 'Djibouti', abbreviation: 'DJ' },
	{ name: 'Dominica', abbreviation: 'DM' },
	{ name: 'Dominican Republic', abbreviation: 'DO' },
	{ name: 'Ecuador', abbreviation: 'EC' },
	{ name: 'Egypt', abbreviation: 'EG' },
	{ name: 'El Salvador', abbreviation: 'SV' },
	{ name: 'Equatorial Guinea', abbreviation: 'GQ' },
	{ name: 'Eritrea', abbreviation: 'ER' },
	{ name: 'Estonia', abbreviation: 'EE' },
	{ name: 'Ethiopia', abbreviation: 'ET' },
	{ name: 'Faroe Islands', abbreviation: 'FO' },
	{ name: 'Fiji', abbreviation: 'FJ' },
	{ name: 'Finland', abbreviation: 'FI' },
	{ name: 'France', abbreviation: 'FR' },
	{ name: 'French Guiana', abbreviation: 'GF' },
	{ name: 'French Polynesia', abbreviation: 'PF' },
	{ name: 'Gabon', abbreviation: 'GA' },
	{ name: 'Gambia', abbreviation: 'GM' },
	{ name: 'Georgia', abbreviation: 'GE' },
	{ name: 'Germany', abbreviation: 'DE' },
	{ name: 'Ghana', abbreviation: 'GH' },
	{ name: 'Greece', abbreviation: 'GR' },
	{ name: 'Greenland', abbreviation: 'GL' },
	{ name: 'Grenada', abbreviation: 'GD' },
	{ name: 'Guadeloupe', abbreviation: 'GP' },
	{ name: 'Guam', abbreviation: 'GU' },
	{ name: 'Guatemala', abbreviation: 'GT' },
	{ name: 'Guinea-Bissau', abbreviation: 'GW' },
	{ name: 'Haiti', abbreviation: 'HT' },
	{ name: 'Honduras', abbreviation: 'HN' },
	{ name: 'Hungary', abbreviation: 'HU' },
	{ name: 'Iceland', abbreviation: 'IS' },
	{ name: 'India', abbreviation: 'IN' },
	{ name: 'Indonesia', abbreviation: 'ID' },
	{ name: 'Iran (Islamic Republic of)', abbreviation: 'IR' },
	{ name: 'Iraq', abbreviation: 'IQ' },
	{ name: 'Ireland', abbreviation: 'IE' },
	{ name: 'Israel', abbreviation: 'IL' },
	{ name: 'Italy', abbreviation: 'IT' },
	{ name: 'Japan', abbreviation: 'JP' },
	{ name: 'Jordan', abbreviation: 'JO' },
	{ name: 'Kazakhstan', abbreviation: 'KZ' },
	{ name: 'Kenya', abbreviation: 'KE' },
	{ name: 'Kiribati', abbreviation: 'KI' },
	{ name: 'North Korea', abbreviation: 'KP' },
	{ name: 'South Korea', abbreviation: 'KR' },
	{ name: 'Kuwait', abbreviation: 'KW' },
	{ name: 'Kyrgyzstan', abbreviation: 'KG' },
	{ name: 'Lao PDR', abbreviation: 'LA' },
	{ name: 'Latvia', abbreviation: 'LV' },
	{ name: 'Lebanon', abbreviation: 'LB' },
	{ name: 'Lesotho', abbreviation: 'LS' },
	{ name: 'Liberia', abbreviation: 'LR' },
	{ name: 'Libya', abbreviation: 'LY' },
	{ name: 'Liechtenstein', abbreviation: 'LI' },
	{ name: 'Lithuania', abbreviation: 'LT' },
	{ name: 'Luxembourg', abbreviation: 'LU' },
	{ name: 'Madagascar', abbreviation: 'MG' },
	{ name: 'Malawi', abbreviation: 'MW' },
	{ name: 'Malaysia', abbreviation: 'MY' },
	{ name: 'Maldives', abbreviation: 'MV' },
	{ name: 'Mali', abbreviation: 'ML' },
	{ name: 'Malta', abbreviation: 'MT' },
	{ name: 'Marshall Islands', abbreviation: 'MH' },
	{ name: 'Martinique', abbreviation: 'MQ' },
	{ name: 'Mauritania', abbreviation: 'MR' },
	{ name: 'Mauritius', abbreviation: 'MU' },
	{ name: 'Mexico', abbreviation: 'MX' },
	{ name: 'Micronesia, Federated States of', abbreviation: 'FM' },
	{ name: 'Moldova', abbreviation: 'MD' },
	{ name: 'Monaco', abbreviation: 'MC' },
	{ name: 'Mongolia', abbreviation: 'MN' },
	{ name: 'Montenegro', abbreviation: 'ME' },
	{ name: 'Montserrat', abbreviation: 'MS' },
	{ name: 'Morocco', abbreviation: 'MA' },
	{ name: 'Mozambique', abbreviation: 'MZ' },
	{ name: 'Myanmar', abbreviation: 'MM' },
	{ name: 'Namibia', abbreviation: 'NA' },
	{ name: 'Nauru', abbreviation: 'NR' },
	{ name: 'Nepal', abbreviation: 'NP' },
	{ name: 'Netherlands', abbreviation: 'NL' },
	{ name: 'New Caledonia', abbreviation: 'NC' },
	{ name: 'New Zealand', abbreviation: 'NZ' },
	{ name: 'Nicaragua', abbreviation: 'NI' },
	{ name: 'Niger', abbreviation: 'NE' },
	{ name: 'Nigeria', abbreviation: 'NG' },
	{ name: 'Northern Mariana Islands', abbreviation: 'MP' },
	{ name: 'Norway', abbreviation: 'NO' },
	{ name: 'Oman', abbreviation: 'OM' },
	{ name: 'Pakistan', abbreviation: 'PK' },
	{ name: 'Palau', abbreviation: 'PW' },
	{ name: 'Palestinian Territory', abbreviation: 'PS' },
	{ name: 'Panama', abbreviation: 'PA' },
	{ name: 'Papua New Guinea', abbreviation: 'PG' },
	{ name: 'Paraguay', abbreviation: 'PY' },
	{ name: 'Peru', abbreviation: 'PE' },
	{ name: 'Philippines', abbreviation: 'PH' },
	{ name: 'Pitcairn', abbreviation: 'PN' },
	{ name: 'Poland', abbreviation: 'PL' },
	{ name: 'Portugal', abbreviation: 'PT' },
	{ name: 'Puerto Rico', abbreviation: 'PR' },
	{ name: 'Qatar', abbreviation: 'QA' },
	{ name: 'Réunion', abbreviation: 'RE' },
	{ name: 'Romania', abbreviation: 'RO' },
	{ name: 'Russian Federation', abbreviation: 'RU' },
	{ name: 'Rwanda', abbreviation: 'RW' },
	{ name: 'Saint Kitts and Nevis', abbreviation: 'KN' },
	{ name: 'Saint Lucia', abbreviation: 'LC' },
	{ name: 'Saint Vincent and Grenadines', abbreviation: 'VC' },
	{ name: 'Samoa', abbreviation: 'WS' },
	{ name: 'San Marino', abbreviation: 'SM' },
	{ name: 'Sao Tome and Principe', abbreviation: 'ST' },
	{ name: 'Saudi Arabia', abbreviation: 'SA' },
	{ name: 'Senegal', abbreviation: 'SN' },
	{ name: 'Serbia', abbreviation: 'RS' },
	{ name: 'Seychelles', abbreviation: 'SC' },
	{ name: 'Sierra Leone', abbreviation: 'SL' },
	{ name: 'Singapore', abbreviation: 'SG' },
	{ name: 'Slovakia', abbreviation: 'SK' },
	{ name: 'Slovenia', abbreviation: 'SI' },
	{ name: 'Solomon Islands', abbreviation: 'SB' },
	{ name: 'Somalia', abbreviation: 'SO' },
	{ name: 'South Africa', abbreviation: 'ZA' },
	{ name: 'Spain', abbreviation: 'ES' },
	{ name: 'Sri Lanka', abbreviation: 'LK' },
	{ name: 'Sudan', abbreviation: 'SD' },
	{ name: 'Suriname', abbreviation: 'SR' },
	{ name: 'Eswatini', abbreviation: 'SZ' },
	{ name: 'Sweden', abbreviation: 'SE' },
	{ name: 'Switzerland', abbreviation: 'CH' },
	{ name: 'Syrian Arab Republic', abbreviation: 'SY' },
	{ name: 'Taiwan (Province of China)', abbreviation: 'TW' },
	{ name: 'Tajikistan', abbreviation: 'TJ' },
	{ name: 'Tanzania', abbreviation: 'TZ' },
	{ name: 'Thailand', abbreviation: 'TH' },
	{ name: 'Timor-Leste', abbreviation: 'TL' },
	{ name: 'Togo', abbreviation: 'TG' },
	{ name: 'Tonga', abbreviation: 'TO' },
	{ name: 'Trinidad and Tobago', abbreviation: 'TT' },
	{ name: 'Tunisia', abbreviation: 'TN' },
	{ name: 'Turkey', abbreviation: 'TR' },
	{ name: 'Turkmenistan', abbreviation: 'TM' },
	{ name: 'Tuvalu', abbreviation: 'TV' },
	{ name: 'Uganda', abbreviation: 'UG' },
	{ name: 'Ukraine', abbreviation: 'UA' },
	{ name: 'United Arab Emirates', abbreviation: 'AE' },
	{ name: 'United Kingdom', abbreviation: 'GB' },
	{ name: 'United States of America', abbreviation: 'US' },
	{ name: 'Uruguay', abbreviation: 'UY' },
	{ name: 'Uzbekistan', abbreviation: 'UZ' },
	{ name: 'Vanuatu', abbreviation: 'VU' },
	{ name: 'Venezuela', abbreviation: 'VE' },
	{ name: 'Viet Nam', abbreviation: 'VN' },
	{ name: 'Virgin Islands, US', abbreviation: 'VI' },
	{ name: 'Yemen', abbreviation: 'YE' },
	{ name: 'Zambia', abbreviation: 'ZM' },
	{ name: 'Zimbabwe', abbreviation: 'ZW' },
]

// country code regex
const CC_REGEX = /^[a-z]{2}$/i

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

/**
 * convert country code to corresponding flag emoji
 * @param {string} cc - country code string
 * @returns {string} flag emoji
 */
export function countryCodeEmoji(cc) {
	if (!CC_REGEX.test(cc)) {
		const type = typeof cc
		throw new TypeError(
			`cc argument must be an ISO 3166-1 alpha-2 string, but got '${type === 'string' ? cc : type}' instead.`,
		)
	}

	const codePoints = [...cc.toUpperCase()].map((c) => c.codePointAt() + OFFSET)
	return String.fromCodePoint(...codePoints)
}
