<template>
	<div class="h-full flex flex-col">
		<div class="text-right">
			<button class="md:hidden p-2" @click.prevent="emit('done')">
				<i class="fa-solid fa-sharp fa-times text-xl" />
			</button>
		</div>
		<div class="p-4 md:p-0 overflow-y-auto items-stretch">
			<div class="flex justify-between">
				<h2 class="font-semibold text-xl flex-grow text-center sm:text-left">Filters</h2>
			</div>
			<div class="space-y-2 mt-4 ml-2">
				<div v-for="status in statuses" :key="status" class="items-center space-x-2">
					<filter-option
						:label="status"
						@click.prevent="onStatusChange(status)"
						:selected="props.selectedStatuses.includes(status)"
					/>
				</div>
			</div>
			<div class="flex justify-between mt-2">
				<h2 class="font-semibold text-xl flex-grow text-center sm:text-left">Collections</h2>
			</div>

			<div class="mt-4 flex-grow">
				<div class="space-y-2">
					<storefront-filter-item
						class="cursor-pointer hover:bg-gray-100 active:bg-gray-200 rounded-lg mouse-down"
						v-for="storefront in props.offerStorefronts"
						:key="`storefront-${storefront.id}`"
						:storefront="storefront"
						:class="[storefrontSelected(storefront) ? 'bg-gray-100' : '']"
						@click.prevent="emit('toggle-storefront', storefront)"
					/>
				</div>
			</div>
		</div>

		<div
			class="md:hidden flex-shrink-0 flex-grow-0 flex justify-between space-x-2 py-4 px-4 border-t border-gray-200 mt-auto"
		>
			<button
				class="shadow hover:shadow-none border border-gray-200 rounded-xl text-center w-full text-sky-600"
				@click.prevent="emit('clear-all-filters')"
			>
				Clear all
			</button>
			<button class="shadow hover:shadow-none btn-primary-lg w-full" @click.prevent="$emit('done')">Done</button>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { defineComponent, defineProps, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { Storefront } from '@/types/Storefront'
import StorefrontFilterItem from '@/components/StorefrontFilterItem.vue'
import FilterOption from '@/components/FilterOption.vue'

const props = defineProps<{
	offerStorefronts: Storefront[]
	filteredStorefronts: Storefront[]
	selectedStatuses: string[]
}>()

const emit = defineEmits<{
	(event: 'done'): void
	(event: 'clear-all-filters'): void
	(event: 'toggle-storefront', storefront: Storefront): void
	(event: 'update-status', status: string, checked: boolean): void

	// (event: 'complete', offer: Offer): void
}>()

const statuses = ['Active', 'Expired', 'Filled', 'Cancelled', 'Terminated']

const storeStore = useStorefrontStore()
const router = useRouter()
const route = useRoute()

function storefrontSelected(storefront: Storefront): boolean {
	return !!props.filteredStorefronts.find((s) => s.id === storefront.id)
}

function onStatusChange(status) {
	emit('update-status', { status })
}

function updateFilterParams() {
	const storeSlugs = props.filteredStorefronts.map((store) => store.slug)
	let activeFilters = {}

	if (storeSlugs.length > 0) {
		activeFilters['Collection'] = storeSlugs
	}

	if (Object.entries(storeStore.state.activeFilters).length > 0) {
		activeFilters = {
			...activeFilters,
			...storeStore.state.activeFilters,
		}
	}

	emit('done')
}

function handleFiltersUpdated(e) {
	console.log('filters updated', e)
}
</script>
