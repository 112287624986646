<template>
	<div class="flex flex-col space-y-4 mb-4">
		<label for="name" class="block text-md font-medium text-gray-700"> {{ props.label }} </label>
		<div class="w-full flex relative space-x-4">
			<div class="w-full">
				<div class="mt-1 relative rounded-md shadow-sm">
					<input
						id="name"
						type="text"
						required
						autocomplete="name"
						aria-describedby="name-error"
						class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
						:class="{
							'border-red-600 focus:ring-red-600 focus:border-red-600':
								localTransferState.errors.shipping_name,
						}"
						aria-required="true"
						aria-label="Name"
						placeholder="Name"
						v-model="localAddress.shipping_name"
						:disabled="localTransferState.txInProgress"
						@blur="localTransferState.errors.shipping_name = !localAddress.shipping_name"
					/>
				</div>
				<p v-if="localTransferState.errors.shipping_name" class="text-sm text-red-600" id="name-error">
					Shipping Name is required
				</p>
			</div>
			<div class="w-full">
				<div class="mt-1 relative rounded-md shadow-sm">
					<form-input-phone
						ref="phoneInput"
						v-model="localAddress.phone"
						@blur="
							localTransferState.errors.phone = phoneInput.phoneNumberUnmasked.length
								? !phoneInput.isMaskCompleted
								: false
						"
						:disabled="localTransferState.txInProgress"
						:class="{
							'border-red-600 focus:ring-red-600 focus:border-red-600': localTransferState.errors.phone,
						}"
					/>
				</div>
				<p v-if="localTransferState.errors.phone" class="text-sm text-red-600">Not a valid Phone Number</p>
			</div>
		</div>
		<div class="mt-1 relative">
			<select
				id="country"
				name="country"
				aria-label="Country"
				v-model="localAddress.country"
				:disabled="localTransferState.txInProgress"
				@change="localTransferState.errors.country = !localAddress.country"
				@blur="localTransferState.errors.country = !localAddress.country"
				class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
				:class="{
					'border-red-600 focus:ring-red-600 focus:border-red-600': localTransferState.errors.country,
				}"
			>
				<option value="" selected disabled>Select a country</option>
				<option v-for="country in countries" :value="country.abbreviation">
					{{ countryCodeEmoji(country.abbreviation) }} {{ country.name }}
				</option>
			</select>
			<p v-if="localTransferState.errors.country" class="text-sm text-red-600">Country is required</p>
		</div>
		<div class="w-full relative">
			<div class="mt-1 relative">
				<input
					id="address_line1"
					type="text"
					class="block w-full pr-10 sm:text-md border-gray-300 rounded-md shadow-sm disabled:bg-slate-50 disabled:opacity-50"
					:class="{
						'border-red-600 focus:ring-red-600 focus:border-red-600':
							localTransferState.errors.address_line1,
					}"
					aria-required="true"
					aria-label="Address Line 1"
					placeholder="Address Line 1"
					v-model="localAddress.address_line1"
					:disabled="localTransferState.txInProgress"
					@blur="localTransferState.errors.address_line1 = !localAddress.address_line1"
				/>
				<p v-if="localTransferState.errors.address_line1" class="text-sm text-red-600">Address is required</p>
			</div>
		</div>
		<div class="w-full relative">
			<div class="mt-1 relative">
				<input
					id="address_line2"
					type="text"
					class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					aria-label="Address Line 2"
					placeholder="Address Line 2"
					v-model="localAddress.address_line2"
					:disabled="localTransferState.txInProgress"
				/>
			</div>
		</div>
		<div class="w-full relative">
			<div class="mt-1 relative">
				<input
					id="address_line3"
					type="text"
					class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					placeholder="Address Line 3"
					v-model="localAddress.address_line3"
					:disabled="localTransferState.txInProgress"
				/>
			</div>
		</div>
		<div class="w-full flex gap-x-4">
			<div class="mt-1 relative">
				<input
					id="city"
					type="text"
					class="block w-full sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					:class="{
						'border-red-600 focus:ring-red-600 focus:border-red-600':
							localTransferState.errors.city_locality,
					}"
					:aria-label="localAddress.country === 'US' ? 'City' : 'City/Locale'"
					:placeholder="localAddress.country === 'US' ? 'City' : 'City/Locale'"
					v-model="localAddress.city_locality"
					:disabled="localTransferState.txInProgress"
					@blur="localTransferState.errors.city_locality = !localAddress.city_locality"
				/>
				<p v-if="localTransferState.errors.city_locality" class="text-sm text-red-600">
					{{ localAddress.country === 'US' ? 'City' : 'City/Locale' }}
					is required
				</p>
			</div>
			<div class="mt-1 relative">
				<select
					v-if="localAddress.country === 'US'"
					id="state"
					name="state"
					v-model="localAddress.state_region"
					:disabled="localTransferState.txInProgress"
					class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					:class="{
						'border-red-600 focus:ring-red-600 focus:border-red-600':
							localTransferState.errors.state_region,
					}"
					@change="
						localTransferState.errors.state_region =
							!localAddress.state_region || localAddress.state_region.length !== 2
					"
				>
					<option value="" selected disabled>State</option>
					<option v-for="state in usStates" :value="state.abbreviation">
						{{ state.name }}
					</option>
				</select>
				<input
					type="text"
					v-else
					id="state"
					name="state"
					v-model="localAddress.state_region"
					class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					:class="{
						'border-red-600 focus:ring-red-600 focus:border-red-600':
							localTransferState.errors.state_region,
					}"
					aria-label="Region"
					placeholder="Region"
					:disabled="localTransferState.txInProgress"
					@blur="localTransferState.errors.state_region = !localAddress.state_region"
				/>
				<p v-if="localTransferState.errors.state_region" class="text-sm text-red-600">
					{{ localAddress.country === 'US' ? 'State' : 'Region' }}
					Required
				</p>
			</div>
			<div class="mt-1 relative">
				<input
					id="zip"
					type="tel"
					class="block w-full sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50 shadow-sm"
					:class="{
						'border-red-600 focus:ring-red-600 focus:border-red-600':
							localTransferState.errors.zip_postal_code,
					}"
					:aria-label="localAddress.country === 'US' ? 'Zip Code' : 'Postal Code'"
					:placeholder="localAddress.country === 'US' ? 'Zip Code' : 'Postal Code'"
					v-model="localAddress.zip_postal_code"
					:disabled="localTransferState.txInProgress"
					@blur="validateZipPostalCode"
				/>
				<p v-if="localTransferState.errors.zip_postal_code" class="text-sm text-red-600">
					Valid
					{{ localAddress.country === 'US' ? 'Zip Code' : 'Postal Code' }}
					Required
				</p>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch } from 'vue'
import { UserAddress } from '@/types/Forms'
import { TransferState } from '@/types/Http'
import { usStates, countries, countryCodeEmoji } from '@/types/FormData'
import FormInputPhone from '@/components/FormInputPhone.vue'

const emit = defineEmits<{
	(event: 'update-address', address: UserAddress): void
	(event: 'update-transfer-state', transferState: TransferState): void
}>()

const phoneInput = ref(null)

const props = withDefaults(
	defineProps<{
		label?: string
		address: UserAddress
		transferState: TransferState
	}>(),
	{
		label: 'Address Information',
	},
)

const localAddress = ref<UserAddress>({ ...props.address })
const localTransferState = ref<TransferState>({ ...props.transferState })

function validateZipPostalCode() {
	const code = localAddress.value.zip_postal_code
	const country = localAddress.value.country

	if (country !== 'US' && !code) {
		localTransferState.value.errors.zip_postal_code = true
		return
	}

	// US Zip code validation
	if (country === 'US') {
		const usZipRegex = /^\d{5}(-\d{4})?$/
		localTransferState.value.errors.zip_postal_code = !usZipRegex.test(code)
	} else {
		localTransferState.value.errors.zip_postal_code = false
	}
}

watch(localAddress.value, (newAddress) => {
	newAddress.phone = phoneInput.value.phoneNumberUnmasked
	newAddress.phoneMasked = phoneInput.value.phoneNumber
	emit('update-address', newAddress)
})

watch(localTransferState.value, (newTransferState) => {
	emit('update-transfer-state', newTransferState)
})
</script>
