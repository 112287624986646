<template>
	<LoadingStorefront v-if="state.loading" />
	<div v-else>
		<storefront-purchase-modal
			v-if="state.selectedListing"
			:asset="state.asset"
			:listing="state.selectedListing"
			@close="state.selectedListing = null"
			@bid-success="handleBidSuccess"
		/>
		<feedback-modal @close="clearFeedback" v-if="null !== state.feedback">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span v-if="state.feedback.success && state.feedback.is_winning">
						<span
							class="inline-flex justify-center items-center text-green-600 bg-green-100 aspect-square px-3 rounded-full"
							><i class="fa-solid fa-check"></i
						></span>
						<span class="ml-3"> Congratulations!</span>
					</span>
					<span v-else-if="state.feedback.success && !state.feedback.is_winning">
						<span
							class="inline-flex justify-center items-center text-blue-600 bg-blue-100 aspect-square px-3 rounded-full"
							><i class="fa-solid fa-info"></i
						></span>
						<span class="ml-3"> So sorry!</span>
					</span>
					<span v-else>
						<span
							class="inline-flex justify-center items-center text-red-600 bg-red-100 aspect-square px-3 rounded-full"
							><i class="fa-regular fa-circle-exclamation"></i
						></span>
						<span class="ml-3"> Something went wrong! </span>
					</span>
				</span>
			</template>
			<template #body>
				<div class="text-xl my-4">
					<p>{{ state.feedback.message }}</p>
					<p>You {{ state.feedback.is_winning ? 'are' : 'are not' }} the highest bidder.</p>
					<p v-if="state.feedback.high_bid">
						The current highest bid is now {{ $format(humanReadablePrice(state.feedback.high_bid)) }}
						{{ $token }}
					</p>
				</div>
			</template>
			<template #buttons="{ close }">
				<button class="btn-primary-lg mt-2" @click.stop="close">Dismiss</button>
			</template>
		</feedback-modal>
		<transfer-asset-modal
			v-if="state.transferAsset"
			:asset="state.asset"
			:max-transferrable="userOwned"
			@close="state.transferAsset = false"
			@complete="loadAsset(), (state.transferAsset = false)"
		/>
		<div>
			<!--
				determine whether or not we show our list for sale button based on
				asset ownership, as well as the total number of assets this user
				has listed (where applicable)
				-->
			<div
				v-if="userOwned > 0"
				class="sticky top-[4.5rem] lg:top-20 bg-gray-50 z-20 shadow-sm px-4 pt-4 pb-4 border-b border-b-slate-200"
			>
				<div class="flex items-center justify-end max-w-7xl mx-auto">
					<button
						@click="state.transferAsset = true"
						v-show="true"
						v-cloak
						title="Transfer this card to another user"
						class="aspect-square w-12 h-12 flex justify-center items-center px-2 py-2 disabled:text-gray-300 disabled:cursor-not-allowed enabled:text-gray-600 leading-none text-sm cursor-pointer rounded-lg border border-transparent enabled:hover:shadow-md enabled:hover:bg-gray-100 enabled:hover:text-gray-900 enabled:hover:border-gray-100 enabled:active:bg-gray-100"
						:disabled="!storeStore.hasFeature(Flags.FEATURE_TRANSFERS)"
					>
						<i class="fa-solid fa-paper-plane" />
					</button>
					<button
						v-show="false"
						v-cloak
						title="Share this card"
						class="aspect-square w-8 flex justify-center items-center text-gray-600 leading-none text-sm border border-transparent rounded-lg hover:shadow-sm active:bg-gray-100"
					>
						<i class="fa fa-share-nodes" />
					</button>

					<div class="text-center flex ml-4 gap-x-4">
						<button
							v-if="state.storefront.flag_redemption_enabled"
							@click="state.isRedeemModalOpen = true"
							:disabled="userListed >= userOwned"
							class="relative px-8 py-3 text-center text-base font-medium text-white rounded-md border border-transparent bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 disabled:text-gray-100"
						>
							Redeem
						</button>

						<router-link
							custom
							v-if="userOwned"
							v-slot="{ navigate }"
							:to="{
								name: 'list-asset',
								params: {
									slug: state.storefront.slug,
									assetId: state.asset.id,
								},
							}"
						>
							<button
								@click="navigate"
								:disabled="userListed >= userOwned || !storeStore.hasFeature(Flags.FEATURE_LISTINGS)"
								class="disabled:cursor-not-allowed enabled:bg-sky-600 enabled:hover:bg-sky-700 relative px-8 py-3 text-center text-base font-medium text-white rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 disabled:text-gray-100"
							>
								List for Sale
							</button>
						</router-link>
					</div>
				</div>
			</div>

			<ListingSuccess v-if="route.query.notice === 'asset_list_success'" />
			<ListingUpdateSuccess v-if="route.query.notice === 'asset_list_update_success'" />
			<ListingRemovalSuccess v-if="route.query.notice === 'asset_list_removal_success'" />
			<AssetPurchaseSuccess v-if="route.query.notice === 'asset_purchase_success'" />
			<InvalidUserError v-if="route.query.error === 'invalid_user_error'" />

			<div class="mx-auto pb-16 mt-6 sm:pb-24 max-w-7xl">
				<div class="sm:grid grid-cols-[2fr,3fr] space-y-4 sm:space-y-0">
					<div class="px-4">
						<h1 class="text-base md:text-lg font-medium text-gray-900">
							<router-link
								:to="{
									name: 'storefront',
									params: { slug: state.storefront.slug },
								}"
								class="text-md font-medium text-sky-600 hover:text-sky-500"
							>
								{{ state.storefront.name }}
							</router-link>
						</h1>
						<h1 class="text-xl md:text-2xl font-medium text-gray-900">
							{{ state.asset.name || `#${state.asset.id}` }}
						</h1>
					</div>

					<div class="px-4 -order-1 row-span-2">
						<div class="">
							<img :src="$cdnify(state.asset.image, 600)" :alt="state.asset.name" class="rounded-xl" />
						</div>
					</div>

					<div class="row-span-3 space-y-4 px-4">
						<div
							class="flex flex-wrap gap-x-2 md:gap-x-4 gap-y-2 items-center justify-center sm:justify-start my-4 text-xs md:text-base font-semibold text-gray-900 hover:bg-gray-50 cursor-text"
						>
							<div class="flex gap-x-1 md:gap-x-2 items-center">
								<span class="text-gray-500 font-normal">ID</span>
								<span> #{{ state.asset.id }} </span>
							</div>
							<button
								v-if="(state.asset.owner_count ?? 0) > 0"
								type="button"
								class="flex gap-x-1 md:gap-x-2 items-center"
							>
								<UsersIcon v-if="(state.asset.token_count ?? 0) != 1" class="size-4 text-gray-400" />
								<UserIcon v-else class="size-4 text-gray-400" />
								<span>
									{{ state.asset.owner_count?.toLocaleString() ?? '0' }}
									<span class="text-gray-500 font-normal">
										<pluralize
											:amount="state.asset.owner_count ?? 0"
											plural="Owners"
											singular="Owner"
										/>
									</span>
								</span>
							</button>
							<button
								v-if="(state.asset.token_count ?? 0) > 0"
								type="button"
								class="flex gap-x-1 md:gap-x-2 items-center"
							>
								<TableCellsIcon class="size-4 text-gray-400" />
								<span>
									{{ state.asset.token_count?.toLocaleString() ?? '0' }}
									<span class="text-gray-500 font-normal">
										<pluralize
											:amount="state.asset.token_count ?? 0"
											plural="Cards"
											singular="Card"
										/>
									</span>
								</span>
							</button>
							<button
								type="button"
								class="flex gap-x-1 md:gap-x-2 items-center"
								@click="handleFavorite(!isUsersFavoriteAsset, state.asset.asset_identifier)"
							>
								<HeartIconSolid v-if="isUsersFavoriteAsset" class="size-4 text-red-500" />
								<HeartIcon v-else class="size-4 text-gray-400" />
								{{ favoritedByCount.toLocaleString() }}
								<span class="text-gray-500 font-normal">
									<pluralize :amount="favoritedByCount" plural="Favorites" singular="Favorite" />
								</span>
							</button>
						</div>

						<!-- MultiToken Buy/Sell Option -->
						<section>
							<div class="grid grid-cols-1 gap-6">
								<div class="rounded-lg border border-gray-200 bg-gray-50 px-6">
									<TabGroup as="div" :default-index="defaultTabIndex">
										<div class="border-b border-gray-200 flex items-center justify-between">
											<TabList class="-mb-px flex">
												<Tab
													v-if="storeStore.hasFeature(Flags.FEATURE_LISTINGS)"
													as="template"
													v-slot="{ selected }"
													:disabled="userOwned == 0"
												>
													<button
														:class="[
															selected
																? 'border-sky-600 text-sky-600'
																: `border-transparent text-gray-700 enabled:hover:border-gray-300 enabled:hover:text-gray-800`,
															'whitespace-nowrap border-b-2 pb-2 pt-3 text-sm font-medium',
															'disabled:text-gray-300 px-4',
														]"
													>
														Sell
													</button>
												</Tab>
												<Tab
													v-if="storeStore.hasFeature(Flags.FEATURE_LISTINGS)"
													as="template"
													v-slot="{ selected }"
												>
													<button
														:class="[
															selected
																? 'border-sky-600 text-sky-600'
																: 'border-transparent text-gray-700 enabled:hover:border-gray-300 hover:text-gray-800',
															'whitespace-nowrap border-b-2 pb-2 pt-3 text-sm font-medium',
															'disabled:text-gray-300 px-4',
														]"
													>
														Buy Now
													</button>
												</Tab>
												<Tab
													v-if="storeStore.hasFeature(Flags.FEATURE_AUCTIONS)"
													as="template"
													v-slot="{ selected }"
													:disabled="auctions.length == 0"
												>
													<button
														:class="[
															selected
																? 'border-sky-600 text-sky-600'
																: 'border-transparent text-gray-700 enabled:hover:border-gray-300 hover:text-gray-800',
															'whitespace-nowrap border-b-2 pb-2 pt-3 text-sm font-medium',
															'disabled:text-gray-300 px-4',
														]"
													>
														Bid
													</button>
												</Tab>
											</TabList>
											<span v-if="userOwned > 0" class="pb-2 pt-3 text-sm font-medium">
												<UserIcon class="mr-1 h-5 w-5 inline-flex" />
												You own {{ userOwned }}
											</span>
										</div>
										<TabPanels as="template">
											<TabPanel class="text-sm text-gray-500">
												<div class="pb-4">
													<router-link
														custom
														v-slot="{ navigate }"
														:to="{
															name: 'list-asset',
															params: {
																slug: state.storefront.slug,
																assetId: state.asset.id,
															},
														}"
													>
														<button
															@click="navigate"
															:disabled="userListed >= userOwned"
															class="relative mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 disabled:text-gray-100"
														>
															List for sale
														</button>
													</router-link>
												</div>
											</TabPanel>
											<TabPanel class="text-sm text-gray-500 pb-4">
												<dl>
													<dd class="mt-2 max-w-none gap-x-[8px]">
														<div class="mt-4" v-if="bestListing">
															<h2 class="text-sm font-medium text-gray-500">
																Current Price:
															</h2>
															<div class="mt-2">
																<p
																	class="text-2xl font-bold tracking-tight text-gray-900"
																>
																	{{
																		$format(
																			humanReadablePrice(
																				bestListing.price *
																					bestListing.quantity,
																			),
																		)
																	}}
																	{{ $token }}
																	<span
																		v-if="bestListing.quantity > 1"
																		class="text-base font-normal text-gray-500 font-poppins"
																		>Quantity: {{ bestListing.quantity }}</span
																	>
																</p>
															</div>
														</div>
													</dd>
													<dd class="mt-4 flex space-x-4">
														<button
															v-if="storeStore.hasFeature(Flags.FEATURE_LISTINGS)"
															:disabled="
																userID == null ||
																bestListing.seller_blokpax_user_id == userID ||
																!bestListing
															"
															type="button"
															class="flex w-1/2 items-center justify-center text-base font-medium text-white px-8 py-3 rounded-md border border-transparent bg-sky-600 hover:bg-sky-700 disabled:bg-gray-400 disabled:text-gray-100 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
															@click="handleBuyNow(bestListing)"
														>
															Buy Now
														</button>
														<button
															v-if="
																flagStore.checkFlag(Flags.OFFERS_CREATE) &&
																storeStore.hasFeature(Flags.FEATURE_OFFERS)
															"
															type="button"
															class="flex w-1/2 items-center justify-center text-base font-medium text-black hover:text-white px-8 py-3 rounded-md border border-transparent bg-gray-200 hover:bg-sky-700 disabled:bg-gray-400 disabled:text-gray-100 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
															:class="
																userID == null ||
																bestListing?.seller_blokpax_user_id == userID ||
																!bestListing
																	? 'enabled:bg-sky-600 enabled:hover:bg-sky-700 text-white'
																	: ''
															"
															@click="handleMakeOffer"
														>
															Make Offer
														</button>
													</dd>
													<dd v-if="userID == null">
														<p class="mt-2">
															<a
																href="//app.blokpax.com/sign-up?next="
																class="href-primary"
																>Create an account</a
															>
															or
															<button @click="handleLoginClicked" class="href-primary">
																Log in
															</button>
															to buy, sell, and negotiate offers.
														</p>
													</dd>
												</dl>
											</TabPanel>
											<TabPanel v-if="storeStore.hasFeature(Flags.FEATURE_AUCTIONS)">
												<div class="mt-4" v-if="lowestBidAuction">
													<p class="py-2 text-gray-500">
														<i class="fa-regular fa-clock"></i>
														<span v-if="DateTime.now() < lowestBidAuction.expires_at">
															Extended bidding begins on
															{{
																lowestBidAuction.expires_at.toFormat(
																	'LLLL d, yyyy @ h:mm a ZZZZ',
																)
															}}
														</span>
														<span v-else>
															Extended bidding ends on
															{{
																lowestBidAuction.extended_bidding_ends_at.toFormat(
																	'LLLL d, yyyy @ h:mm a ZZZZ',
																)
															}}
														</span>
													</p>

													<div>
														<p class="text-gray-500 text-sm">Current bid</p>
														<p>
															<span
																class="text-2xl font-bold tracking-tight text-gray-900"
															>
																{{
																	$format(
																		humanReadablePrice(
																			Math.max(
																				lowestBidAuction.high_bid_amount ?? 0,
																				lowestBidAuction.price,
																			),
																		),
																	)
																}}
																{{ $token }}
															</span>
														</p>
														<p class="my-4">
															<button
																class="flex w-1/2 items-center justify-center text-base font-medium text-white px-8 py-3 rounded-md border border-transparent bg-sky-600 hover:bg-sky-700 disabled:bg-gray-400 disabled:text-gray-100 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
																@click="handleBuyNow(lowestBidAuction)"
															>
																<span class=""
																	><i class="fa-regular fa-gavel"></i
																></span>
																&nbsp; Place Bid
															</button>
														</p>
													</div>
												</div>
											</TabPanel>
										</TabPanels>
									</TabGroup>
								</div>
							</div>
						</section>

						<div class="border border-gray-200 bg-gray-50 rounded-xl" v-if="false">
							<p
								class="p-6 border-b border-gray-200 text-lg font-medium leading-6 text-gray-900 space-x-2"
							>
								<i class="fa-sharp fa-regular fa-bars-sort"></i>
								Description
							</p>
							<div class="p-6 space-y-4">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pretium elit augue,
									eget blandit ex facilisis nec. Cras mollis dictum scelerisque. Praesent ut dapibus
									leo. Donec turpis tortor, hendrerit at auctor et, tincidunt ac sem. Phasellus lorem
									mi, tristique ac rutrum ac, elementum sit amet justo. Nunc varius ac sapien ac
									euismod. Integer venenatis urna suscipit, bibendum justo et, lacinia neque.
									Vestibulum ligula ipsum, sodales a placerat eget, eleifend quis nibh. Nulla in
									venenatis lorem, eu cursus augue. Donec blandit mi nisl, quis fringilla ligula
									maximus nec. Donec eu tincidunt quam, in congue augue. Ut consequat, turpis quis
									malesuada semper, tellus nibh lobortis eros, nec venenatis justo metus sit amet est.
									Mauris maximus, tortor ut tempor tincidunt, lorem lectus aliquam arcu, pulvinar
									tincidunt lorem nisl vitae elit. Quisque convallis rhoncus ipsum, in sagittis diam
									aliquam ullamcorper.
								</p>

								<p>
									Praesent purus augue, luctus at enim vitae, maximus dignissim nunc. Curabitur rutrum
									posuere erat quis commodo. Aenean felis tortor, tempus eu varius vel, vulputate id
									diam. Praesent eget ex nec tortor vehicula fringilla eget vel ex. Sed sit amet justo
									felis. Proin congue ex eu ullamcorper eleifend. Pellentesque habitant morbi
									tristique senectus et netus et malesuada fames ac turpis egestas. Quisque at justo
									ornare sapien euismod posuere eget vel sem.
								</p>
							</div>
						</div>

						<!-- Price History -->
						<storefront-asset-price-history class="hidden mt-10" />

						<!-- Auctions -->
						<storefront-asset-auctions
							v-if="storeStore.hasFeature(Flags.FEATURE_AUCTIONS)"
							class="mt-10"
							:storefront="state.storefront"
							:asset="state.asset"
							:auctions="auctions"
							@buy="handleBuyNow"
						>
							<template #header>
								<span class="inline-block mr-2"><i class="fa-solid fa-gavel"></i></span>
								Auctions
							</template>
						</storefront-asset-auctions>

						<!-- Listings -->
						<storefront-asset-listings
							class="mt-10"
							:storefront="state.storefront"
							:asset="state.asset"
							:listings="listings"
							@buy="handleBuyNow"
						/>

						<!-- Active Offers -->
						<storefront-asset-offers
							class="mt-10"
							v-if="storeStore.hasFeature(Flags.FEATURE_OFFERS)"
							:asset="(state.asset as Asset)"
							:is-asset-owner="userOwned > 0"
							@reload-asset="loadAsset"
						/>
					</div>

					<div class="pt-4">
						<!-- Asset Details & Metadata -->
						<div class="px-4">
							<detail-accordian v-if="hasDrip" name="Drip" :open="true" class="rounded-t-xl">
								<drip-details
									:storefront="state.storefront"
									:asset="state.asset"
									:drip="drip"
									:claimable="userOwned > 0 && userListed == 0"
								/>
							</detail-accordian>
							<!-- Traits -->
							<detail-accordian name="Traits" :open="true" :class="{ 'rounded-t-xl': !hasDrip }">
								<ul
									v-for="attribute in state.asset.metadata"
									:key="attribute"
									role="list"
									class="hidden"
								>
									<li>
										{{ attribute.trait_type }}:
										{{ attribute.value }}
									</li>
								</ul>
								<div
									class="overflow-hidden space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-2 md:mx-0 md:rounded-2xl"
								>
									<div
										v-for="attribute in state.asset.metadata"
										class="bg-gray-200/60 py-4 rounded-xl prose prose-sm text-center"
									>
										<p class="">{{ attribute.value }}</p>
										<p class="text-sm text-gray-500 font-medium">
											{{ attribute.trait_type }}
										</p>
									</div>
								</div>
							</detail-accordian>

							<!-- Description -->
							<detail-accordian
								name="Description"
								:open="assetDescription.length > 0"
								class="border-t-0 rounded-b-xl"
							>
								<div v-html="assetDescription" class="blokpax-description"></div>
							</detail-accordian>

							<div class="text-sm font-poppins font-light px-4 py-2 text-gray-500">
								Last Refreshed:
								<time class="timeago" :datetime="state.asset.metadata_refreshed_at.toString()">
									{{
										state.asset.metadata_refreshed_at?.toLocaleString(
											DateTime.DATETIME_MED_WITH_SECONDS,
										)
									}}
								</time>
							</div>
						</div>
					</div>
				</div>

				<!-- Item Activity -->
				<div class="p-4">
					<ActivityTable
						class="mt-6 md:mt-10"
						v-if="state.asset"
						:show-asset="false"
						:data-loader="loadActivity"
					/>
				</div>
			</div>
		</div>
	</div>
	<create-offer-modal
		v-if="state.isCreateOfferModalOpen"
		:asset="(state.asset as Asset)"
		:token-count="state.asset.token_count ?? 0"
		@close="state.isCreateOfferModalOpen = false"
		@complete="handleCompletedOffer"
	/>

	<redemption-modal
		v-if="state.isRedeemModalOpen && state.asset"
		:asset="(state.asset as Asset)"
		@close="handleRedemptionModal(false)"
		@reload="handleRedemptionModal(true)"
	/>
</template>

<script lang="ts" setup>
import { defineComponent, reactive, ref, toRaw, isProxy, onMounted, onUnmounted, watch, computed } from 'vue'
import LoadingStorefront from '@/components/LoadingStorefront.vue'
import StorefrontPurchaseModal from '@/components/modals/StorefrontPurchaseModal.vue'
import TransferAssetModal from '@/components/modals/TransferAssetModal.vue'
import BPXPurchaseModal from '@/components/modals/BPXPurchaseModal.vue'
import CCPurchaseModal from '@/components/modals/CCPurchaseModal.vue'
import StorefrontAssetOffers from '@/components/StorefrontAssetOffers.vue'
import StorefrontAssetListings from '@/components/StorefrontAssetListings.vue'
import StorefrontAssetAuctions from '@/components/StorefrontAssetAuctions.vue'
import StorefrontAssetPriceHistory from '@/components/StorefrontAssetPriceHistory.vue'
import DetailAccordian from '@/components/DetailAccordian.vue'
import { DateTime } from 'ts-luxon'
import ToUsd from '@/components/ToUsd.vue'
import FeedbackModal from '@/components/modals/FeedbackModal.vue'
import { TransitionRoot, TransitionChild, TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { UsersIcon, HeartIcon, TableCellsIcon, UserIcon } from '@heroicons/vue/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/24/solid'
import sleep from '@/util/sleep'
import { useAsset } from '@/components/assets/asset'
import Currency from '@/types/Currency'

import { useAuthStore } from '@/stores/AuthStore'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAssetStore } from '@/stores/AssetStore'
import {
	ListingSuccess,
	ListingUpdateSuccess,
	ListingRemovalSuccess,
	AssetPurchaseSuccess,
} from '@/components/notices/storefront/notices'
import {
	InvalidUserError,
	ListingError,
	ListingRemovalError,
	ListingUpdateError,
} from '@/components/Errors/storefront/errors'
import { humanReadablePrice } from '@/util/currencyFormat'
import { useRoute, useRouter } from 'vue-router'
import ActivityTable from '@/components/Activity/ActivityTable.vue'
import DripDetails from '@/components/Drips/DripDetails.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { Listing, Asset, AssetActivity, TYPE_AUCTION, TYPE_FIXED } from '@/types/Asset'
import { Offer, Storefront } from '@/types/Storefront'
import { Flags } from '@/types/Flags'
import { useUserFavoriteStore } from '@/stores/UserFavoriteStore'
import useDrips from '@/modules/UseDrips'
import { Drip } from '@/types/Drip'
import { useDripStore } from '@/stores/DripStore'
import CreateOfferModal from '@/components/modals/CreateOfferModal.vue'
import { useFlagStore } from '@/stores/FlagStore'
import { Flag } from '@/types/Flags'
import RedemptionModal from '@/components/modals/RedemptionModal.vue'

const open = ref(false)
const authStore = useAuthStore()
const storeStore = useStorefrontStore()
const assetStore = useAssetStore()
const marketStore = useMarketStore()
const route = useRoute()
const router = useRouter()
const dripStore = useDripStore()
const userFavStore = useUserFavoriteStore()
const flagStore = useFlagStore()

const props = defineProps<{
	assetId: number
}>()

const drip = computed<Drip | null>(() => {
	if (!dripStore.drips[state.asset.asset_identifier]) {
		return null
	}
	return dripStore.drips[state.asset.asset_identifier]
})

const hasDrip = computed<boolean>((): boolean => {
	return !(drip.value === null)
})

const state = reactive<{
	loading: boolean
	showPurchaseModal: boolean
	transferAsset: boolean
	asset: null | Asset
	storefront: null | Storefront
	showBpxPurchaseModal: boolean
	selectedListing: null | Listing
	feedback: null | { success: boolean; message?: string; is_winning?: boolean; high_bid?: number }
	isCreateOfferModalOpen: boolean
	isConfirmingCancelOffer: boolean
	offerToCancel: Offer | null
	isRedeemModalOpen: boolean
	isConfirmingRedeem: boolean
}>({
	loading: true,
	showPurchaseModal: false,
	transferAsset: false,
	asset: null,
	storefront: null,
	showBpxPurchaseModal: false,
	selectedListing: null,
	feedback: null,
	// list_amount: 1,
	isCreateOfferModalOpen: false,
	isConfirmingCancelOffer: false,
	offerToCancel: null,
	isRedeemModalOpen: false,
	isConfirmingRedeem: false,
})

const userID = computed(() => {
	return authStore.user?.id ?? null
})

watch(
	() => state.asset,
	(cur, old) => {
		if (!cur) {
			console.error(`Asset with ID: ${props.assetId} not found. Return to storefront page.`)
			return
		}
	},
)

async function loadAsset() {
	state.loading = true
	const wait = sleep(0.75)
	await useAsset(route.params.slug as string, +route.params.assetId as number, authStore.user).then((response) => {
		response = isProxy(response) ? toRaw(response) : response
		state.asset = response
		state.storefront = response.storefront
	})

	await storeStore.loadStore(route.params.slug as string)
	await marketStore.load()

	wait.then(() => (state.loading = false))
}

onMounted(async () => {
	loadAsset()
		.then(() => {
			if (route.params.listingId) {
				const listing = state.asset.listings.find((l) => l.id == route.params.listingId)

				if (listing) {
					state.selectedListing = listing
				} else {
					alert('That listing is no longer active.')
				}
			}
		})
		.then(async () => {
			await dripStore.load(state.asset.asset_identifier)
			dripStore.watch(state.asset.asset_identifier)
		})
})

onUnmounted(() => {
	dripStore.unwatch(state.asset.asset_identifier)
})

function clearFeedback() {
	router.replace({
		path: route.fullPath,
	})

	state.feedback = null
}

const tabs = [
	{ name: 'Buy', href: '#', current: false, disabled: false },
	{ name: 'Sell', href: '#', current: false, disabled: false },
]

const userOwned = computed(() => {
	if (!authStore.authenticated) {
		return 0
	}

	return state.asset.user_balance ?? 0
})

const userListed = computed(() => {
	if (!authStore.authenticated) {
		return 0
	}

	if ((state.asset.token_count ?? 0) == 0) {
		return 0
	}

	return state.asset.listings.reduce((total, listing) => {
		if (listing.seller_blokpax_user_id == authStore.user.id) {
			return total + listing.quantity
		}

		return total
	}, 0)
})

const defaultTabIndex = computed(() => {
	if (userOwned.value > 1) {
		if (userListed < userOwned) {
			return 0
		}
	}

	if (bestListing.value) {
		if (lowestBidAuction.value) {
			if (
				bestListing.value.price <=
				lowestBidAuction.value.high_bid_amount + lowestBidAuction.value.bid_increment
			) {
				return 1
			}

			return 2
		}

		return 1
	} else if (lowestBidAuction.value) {
		return 2
	}

	return 1
})

const bestListing = computed(() => {
	if (listings.value.length == 0) {
		return false
	}

	return [...listings.value].sort((l, r) => l.price - r.price)[0]
})

const listings = computed(() => {
	if (state.asset.listings.length == 0) {
		return [] as Listing[]
	}

	return state.asset.listings.filter((listing) => listing.listing_type == TYPE_FIXED) as Listing[]
})

const auctions = computed(() => {
	if (state.asset.listings.length == 0) {
		return []
	}

	return state.asset.listings.filter((listing) => listing.listing_type == TYPE_AUCTION)
})

const lowestBidAuction = computed(() => {
	return (
		[...auctions.value].sort((l, r) => {
			return (
				(l.high_bid_amount ?? Number(l.price) + l.bid_increment) -
				(r.high_bid_amount ?? Number(r.price) + r.bid_increment)
			)
		})[0] ?? null
	)
})

const handleBuyNow = (listing) => {
	state.selectedListing = listing
}

function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}

async function loadActivity(page: number | null = null): Promise<{ data: Object; meta: Object }> {
	return assetStore.loadActivity(state.storefront.slug, state.asset.id + '', page ?? 1) as Promise<{
		data: AssetActivity[]
		meta: Object
	}>
}

function handleBidSuccess(bidFeedback: { high_bid: number; is_winning: boolean; success: boolean }) {
	state.feedback = bidFeedback
	loadAsset()
}

const isUsersFavoriteAsset = computed(() => {
	return userFavStore.isAssetFavorited(state.asset.asset_identifier)
})

const isUserAuthenticated = computed(() => {
	return authStore.authenticated
})

const favoritedByCount = computed(() => {
	return state.asset.favorited_by || 0
})

async function handleFavorite(isFavorite: boolean, assetId: string) {
	try {
		await userFavStore.favoriteAsset(isFavorite, assetId)
		if (isFavorite) {
			state.asset.favorited_by = state.asset.favorited_by + 1
		} else {
			state.asset.favorited_by = state.asset.favorited_by - 1
		}
	} catch (e) {
		console.error(e)
	}
}

function handleMakeOffer() {
	state.isCreateOfferModalOpen = true
}

function handleCompletedOffer() {
	state.isCreateOfferModalOpen = false
	loadAsset()
}

const assetDescription = computed(
	() => state.asset?.description_html || state.storefront?.details?.description_html || '',
)

async function handleRedemptionModal(shouldReload: boolean) {
	state.isRedeemModalOpen = false

	if (shouldReload) {
		await loadAsset()
	}
}
</script>

<style lang="postcss">
.blokpax-description {
	a {
		@apply text-sky-600 hover:text-sky-700;
	}
	p {
		@apply mb-4;
		&:last-child {
			@apply mb-0;
		}
	}
}
</style>
