<template>
	<section v-if="state.isLoading" class="flex flex-col justify-center items-center">
		<p class="my-4">Loading...</p>
		<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
	</section>
	<section v-else>
		<header
			class="bg-gray-50 py-4 px-8 sticky top-[4.5rem] lg:top-[5rem] z-20 border-b border-gray-300 h-16 flex items-center"
		>
			<button
				class="shadow px-4 md:py-2 aspect-square md:aspect-auto border border-gray-300 rounded-xl space-x-2 font-medium mr-4"
				@click.prevent="toggleFilters"
			>
				<i class="fa-solid fa-bars-filter"></i>
				<span class="hidden md:inline-block">Filter</span>
			</button>

			<p>{{ state.offers.length }} Offers</p>
		</header>

		<!-- main area -->
		<div class="flex">
			<!-- filters -->
			<div
				class="transition-all w-screen h-screen p-4 border-r overflow-y-auto fixed z-50 bg-gray-50 border-gray-300 lg:pl-8 lg:z-0 lg:sticky lg:max-w-xs lg:h-[calc(100vh-9rem)] lg:top-[9rem] lg:translate-x-0"
				:class="{
					'top-full': !state.isFilterOpen,
					'top-0': state.isFilterOpen,
					'lg:hidden': !state.isFilterOpen,
					'lg:block': state.isFilterOpen,
				}"
			>
				<button @click.prevent="toggleFilters" class="absolute right-8 top-4 lg:hidden">
					<i class="fa-solid fa-sharp fa-times text-xl" />
				</button>
				<div class="mx-2 mb-4">
					<form class="max-w-full mx-auto">
						<div class="flex justify-between items-center">
							<p class="text-lg text-center lg:text-left font-bold mb-2">Filters</p>
							<button
								v-if="state.isFiltering"
								@click.prevent="state.isFiltering = false"
								class="rounded bg-gray-200 text-xs px-2 py-1 hover:bg-gray-300 flex items-center"
							>
								<span class="mr-1">Clear</span><i class="fa fa-close"></i>
							</button>
						</div>
						<div class="relative">
							<div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg
									class="w-4 h-4 text-gray-500"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 20"
								>
									<path
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
									/>
								</svg>
							</div>
							<input
								type="search"
								class="block w-full p-4 ps-10 text-sm border border-gray-300 rounded-lg bg-gray-50 placeholder-gray-400"
								placeholder="Search Traits..."
								v-model="state.searchFilter"
							/>
						</div>
					</form>
				</div>
				<div
					class="hover:bg-gray-200 p-2 rounded-lg cursor-pointer mb-1"
					:class="{
						'bg-gray-200': state.isFiltering === false,
					}"
					@click="state.isFiltering = false"
				>
					<div class="">All Offers</div>
				</div>
				<div
					class="hover:bg-gray-200 p-2 rounded-lg cursor-pointer"
					:class="{
						'bg-gray-200': state.isFiltering && state.currentFilter === null,
					}"
					@click="handleFilterClick(null)"
				>
					<div class="">Any Trait</div>
				</div>
				<div
					v-for="trait of state.filterOptions"
					class="hover:bg-gray-200 my-1 p-2 rounded-lg cursor-pointer"
					@click="handleFilterClick(trait)"
					:class="{
						'bg-gray-200': state.isFiltering && state.currentFilter === trait,
					}"
					v-show="
						trait.prop.toLowerCase().includes(state.searchFilter.toLowerCase()) ||
						trait.val.toLowerCase().includes(state.searchFilter.toLowerCase())
					"
				>
					<div class="">
						<p class="text-sm">{{ trait.prop }}</p>
						{{ trait.val }}
					</div>
				</div>
			</div>

			<!-- offers -->
			<div class="flex-grow">
				<div class="sticky top-[8.5rem] lg:top-[9rem] bg-gray-100 border-b border-gray-300 z-20">
					<div class="flex items-center justify-between h-16 p-4">
						<p class="text-left w-7/12">Offer Amount</p>
						<p class="w-2/12 hidden sm:block">Total Volume</p>
						<p class="w-3/12 hidden sm:block">Available</p>
					</div>
				</div>
				<div class="p-4 flex justify-center" v-if="state.offers.length === 0">No current offers available</div>
				<article
					v-else
					v-for="offer of offers"
					@click="handleOfferClick(offer)"
					class="p-2 cursor-pointer hover:bg-gray-200 flex justify-between items-center group"
				>
					<div
						class="flex flex-col items-stretch flex-start relative grow h-full px-2 text-left w-7/12 sm:mr-4"
					>
						<div class="relative z-10 p-2">
							<div class="flex justify-between items-center w-full">
								<p class="text-lg">
									{{ $format(humanReadablePrice(BigInt(offer.offer_bpx_per_token).toString())) }}
									{{ $token }}
								</p>
							</div>
							<div
								class="absolute bg-sky-600 h-[90%] opacity-25 top-1/2 left-0 transform -translate-y-1/2 rounded-lg z-0"
								:style="{
									width: `${
										((toFloat(BigInt(offer.offer_bpx_per_token)) * offer.total_offer_token_count) /
											highestOfferVol) *
										100
									}%`,
								}"
							></div>
						</div>
						<div class="flex justify-between w-full">
							<div class="flex justify-between items-center w-full">
								<div class="mt-1z">
									<p class="text-sm sm:hidden">
										Available: {{ offer.total_offer_token_count - offer.tokens_fulfilled }}

										<span v-if="offer.expires_at"
											>until
											<!-- some day -->
											<template
												v-if="
													DateTime.fromISO(offer.expires_at).minus({ hours: 24 }) >
													DateTime.now()
												"
											>
												{{ DateTime.fromISO(offer.expires_at).toFormat('D') }}
											</template>

											<!-- tomorrow -->
											<template
												v-else-if="
													DateTime.fromISO(offer.expires_at).day ===
													DateTime.now().plus({ day: 1 }).day
												"
											>
												{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} tomorrow
											</template>

											<!-- today -->
											<template v-else>
												{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} today
											</template>
										</span>
									</p>
									<p class="text-sm sm:hidden ml-auto">
										Volume:
										{{
											$format(
												humanReadablePrice(
													offer.offer_bpx_per_token * offer.total_offer_token_count,
												),
											)
										}}
										{{ $token }}
									</p>
									<p class="text-sm">
										Trait: "{{
											offer.offer_for_trait_prop && offer.offer_for_trait_val
												? `${offer.offer_for_trait_prop}: ${offer.offer_for_trait_val}`
												: 'Any'
										}}"
									</p>
								</div>
								<button
									v-if="offer.created_by_user_id === authStore.user?.id"
									@click.stop="confirmOfferCancel(offer)"
									class="sm:hidden text-center rounded hover:bg-gray-300 hover:text-red-600 text-gray-400 ml-4"
								>
									<i class="fas fa-trash"></i>
								</button>
							</div>
						</div>
					</div>
					<div class="w-2/12 hidden sm:block">
						<p>
							{{
								$format(
									humanReadablePrice(
										(
											BigInt(offer.offer_bpx_per_token) *
											BigInt(offer.total_offer_token_count - offer.tokens_fulfilled)
										).toString(),
									),
								)
							}}
							{{ $token }}
						</p>
					</div>
					<div class="w-3/12 hidden sm:flex sm:items-center sm:justify-between">
						<div>
							<div>
								{{ offer.total_offer_token_count - offer.tokens_fulfilled }}
							</div>

							<span v-if="offer.expires_at" class="text-xs">
								until
								<!-- some day -->
								<template
									v-if="DateTime.fromISO(offer.expires_at).minus({ hours: 24 }) > DateTime.now()"
								>
									{{ DateTime.fromISO(offer.expires_at).toFormat('D') }}
								</template>

								<!-- tomorrow -->
								<template
									v-else-if="
										DateTime.fromISO(offer.expires_at).day === DateTime.now().plus({ day: 1 }).day
									"
								>
									{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} tomorrow
								</template>

								<!-- today -->
								<template v-else>
									{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} today
								</template>
							</span>
						</div>
						<p class="ml-auto pl-2 invisible group-hover:visible"><i class="fa fa-eye"></i></p>
						<button
							v-if="offer.created_by_user_id === authStore.user?.id"
							@click.stop="confirmOfferCancel(offer)"
							class="hidden sm:block text-center rounded px-2 py-1 right-4 z-10 hover:text-red-600 text-gray-400"
						>
							<i class="fas fa-trash"></i>
						</button>
					</div>
				</article>
			</div>
		</div>
	</section>
	<cancel-offer-modal
		v-if="state.isConfirmingCancelOffer"
		:offer="state.offerToCancel"
		@close="handleCancelModal(false)"
		@reload="handleCancelModal(true)"
	/>
</template>
<script lang="ts" setup>
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { ComputedRef, computed, onBeforeMount, onMounted, onUnmounted, reactive, ref } from 'vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import { Offer } from '@/types/Storefront'
import { Trait } from '@/types/Asset'
import { useAuthStore } from '@/stores/AuthStore'
import { RouteLocationRaw, useRouter } from 'vue-router'
import CancelOfferModal from '@/components/modals/CancelOfferModal.vue'
import { DateTime } from 'ts-luxon'

const router = useRouter()
const authStore = useAuthStore()
const storeStore = useStorefrontStore()
const state = reactive<{
	offers: Offer[]
	isLoading: boolean
	filterOptions: Trait[]
	currentFilter: Trait | null
	searchFilter: string
	isFilterOpen: boolean
	isCreateOfferModalOpen: boolean
	isFiltering: boolean
	isConfirmingCancelOffer: boolean
	offerToCancel: Offer | null
}>({
	offers: [],
	isLoading: false,
	filterOptions: [],
	currentFilter: null,
	searchFilter: '',
	isFilterOpen: false,
	isCreateOfferModalOpen: false,
	isFiltering: false,
	isConfirmingCancelOffer: false,
	offerToCancel: null,
})

onBeforeMount(async () => {
	await loadOffers()
	handleResize()
})

onMounted(async () => {
	window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
	window.removeEventListener('resize', handleResize)
})

async function loadOffers() {
	state.isLoading = true

	const offers = await storeStore.getStorefrontOffers()
	state.offers = offers

	const filters = offers.reduce((filters, offer: Offer) => {
		if (offer.offer_for_asset) {
			return filters
		}

		const { offer_for_trait_prop, offer_for_trait_val } = offer

		if (
			offer_for_trait_prop !== null &&
			offer_for_trait_val !== null &&
			!filters.find((filter: Trait) => filter.prop === offer_for_trait_prop && filter.val === offer_for_trait_val)
		) {
			filters.push({
				prop: offer_for_trait_prop,
				val: offer_for_trait_val,
			})
		}

		filters.sort((a: Trait, b: Trait) => a.prop.localeCompare(b.prop))

		return filters
	}, [])

	state.filterOptions = filters
	state.isLoading = false
}

function handleOfferClick(offer: Offer) {
	const route: RouteLocationRaw = { name: 'storefront' }
	const query = {}
	if (offer.offer_for_trait_prop !== null && offer.offer_for_trait_val !== null) {
		query[offer.offer_for_trait_prop] = offer.offer_for_trait_val
		route.query = query
	}
	router.push(route)
}

function toggleFilters() {
	state.isFilterOpen = !state.isFilterOpen
}

const handleResize = () => {
	state.isFilterOpen = window.innerWidth >= 1024
}

const handleFilterClick = (trait: Trait | null) => {
	state.isFiltering = true

	if (trait === null) {
		state.currentFilter = null
		state.searchFilter = ''
	} else {
		state.currentFilter = trait
	}

	if (window.innerWidth < 1024) {
		state.isFilterOpen = false
	}
}

const offers = computed(() => {
	return state.isFiltering ? filteredOffers.value : state.offers
})

const filteredOffers = computed(() => {
	const filteredOffers = state.offers.filter((offer) => {
		if (state.currentFilter === null) {
			return offer.offer_for_trait_prop === null && offer.offer_for_trait_val === null
		}

		return (
			offer.offer_for_trait_prop === state.currentFilter.prop &&
			offer.offer_for_trait_val === state.currentFilter.val
		)
	})

	return filteredOffers
})

const highestOfferVol: ComputedRef<number> = computed(() => {
	return Math.max(
		...offers.value.map((offer) => toFloat(BigInt(offer.offer_bpx_per_token)) * offer.total_offer_token_count),
	)
})

// Convert Bipixies (no decimals) to BPX (9 decimal places)
function toFloat(value: bigint): number {
	const integralPart = value / BigInt(1e9)
	const decimalPart = Number(value % BigInt(1e9)) / 1e9
	return Number(integralPart) + decimalPart
}

function confirmOfferCancel(offer: Offer) {
	state.offerToCancel = offer
	state.isConfirmingCancelOffer = true
}
async function handleCancelModal(shouldReload: boolean) {
	state.isConfirmingCancelOffer = false
	state.offerToCancel = null
	if (shouldReload) {
		await loadOffers()
	}
}
</script>

<style scoped></style>
