<style scoped type="text/css">
input:checked ~ div#content {
	overflow: visible;
	display: block;
	line-clamp: none;
	-webkit-line-clamp: none;
	-webkit-box-orient: horizontal;
}
input:checked ~ div#gradient {
	display: none !important;
}

input:checked ~ label #show-less {
	display: inline;
}

input:checked ~ label #show-more {
	display: none;
}

input:not(:checked) ~ label #show-less {
	display: none;
}

input:not(:checked) ~ label #show-more {
	display: inline;
}
</style>
<template>
	<div class="">
		<div class="relative">
			<div class="w-full h-64 bg-cover" :style="[`background-image: url('${props.storefront.bg_image}')`]"></div>
			<div
				class="absolute left-4 -bottom-[2rem] aspect-square p-2 rounded-2xl bg-white shadow-md overflow-hidden"
			>
				<div class="w-32 h-32 overflow-hidden rounded-xl">
					<img :src="props.storefront.pfp_image" class="w-full rounded-xl" />
				</div>
			</div>
		</div>

		<div class="mx-4 mt-12">
			<h1 class="text-2xl md:text-4xl font-semibold">{{ props.storefront.name }}</h1>
			<h2 class="text-base md:text-xl text-gray-500 font-medium tracking-tight">
				{{ props.storefront.group_name }}
			</h2>

			<div class="flex flex-wrap my-4 gap-2">
				<template v-for="(link, index) in linksArray" :key="`link-${index}`">
					<a
						target="_blank"
						:href="link.url"
						class="text-sm md:text-base text-sky-800 fah:text-white bg-transparent fah:bg-sky-800 border border-sky-800/50 fah:border-sky-800 flex items-center gap-2 rounded-md px-2 py-2 transition-colors"
					>
						<fa :icon="iconMap[link.icon]" class="text-lg" />
						<span>{{ link.label }}</span>
					</a>
				</template>
			</div>
			<div
				v-if="!props.loading"
				class="relative [&_a]:text-sky-600 [&_a]:underline [&_a:hover]:no-underline [&_li]:ml-4 [&_ul]:list-disc [&_ul]:ml-4 overflow-hidden"
			>
				<input type="checkbox" class="hidden pointer-events-none leading-0 overflow-hidden" id="expanded" />
				<div id="content" class="line-clamp-5 space-y-2" v-html="props.storefront.description_html"></div>
				<div
					id="gradient"
					class="flex items-end absolute bottom-0 left-0 right-0 w-full h-20 bg-gradient-to-t from-gray-50 to-transparent"
				></div>
				<label
					class="relative z-10 px-2 py-1 text-sky-600 hover:bg-gray-200/50 rounded-lg text-sm mt-2 inline-block"
					for="expanded"
					role="button"
					>See <span id="show-more">more</span><span id="show-less">less</span></label
				>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { PropType, computed, onMounted, onUnmounted, ref } from 'vue'
import { Storefront } from '@/types/Storefront'
import { faXTwitter, faDiscord } from '@awesome.me/kit-428607b2fd/icons/classic/brands'
import { faPalette, faGlobe } from '@awesome.me/kit-428607b2fd/icons/classic/light'

const props = withDefaults(
	defineProps<{
		storefront: Storefront
		loading?: Boolean
	}>(),
	{
		loading: false,
	},
)

const iconMap = {
	discord: faDiscord,
	x: faXTwitter,
	palette: faPalette,
	globe: faGlobe,
}

let clampedContent = null
let seeMoreLabel = null
let gradientOverlay = null

const iconsLoaded = ref(false)

const linksArray = computed(() => {
	return Array.isArray(props.storefront.links) ? props.storefront.links : []
})

function checkOverflow() {
	if (clampedContent.scrollHeight > clampedContent.clientHeight) {
		seeMoreLabel.style.display = 'inline-block'
		gradientOverlay.style.display = 'block'
	} else {
		seeMoreLabel.style.display = 'none'
		gradientOverlay.style.display = 'none'
	}
}

onMounted(() => {
	iconsLoaded.value = true

	clampedContent = document.querySelector('.line-clamp-5')
	seeMoreLabel = document.querySelector("label[for='expanded']")
	gradientOverlay = document.querySelector('.bg-gradient-to-t')

	checkOverflow()
	window.addEventListener('resize', checkOverflow) // Recheck on resize
})

onUnmounted(() => {
	window.removeEventListener('resize', checkOverflow)
})
</script>
