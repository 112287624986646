<template>
	<div class="p-2 bg-white col-span-full grid grid-cols-subgrid gap-x-2">
		<div class="whitespace-nowrap p-2 flex text-xs md:text-sm space-x-2 items-center col-span-full @3xl:col-span-1">
			<slot name="action">
				<Action :action="activity?.action" />
			</slot>
		</div>

		<div class="p-2 text-sm font-medium text-gray-900 flex items-center col-span-full @3xl:col-span-1">
			<router-link
				v-if="activity?.asset"
				:to="{
					name: 'asset',
					params: { slug: getSlug(activity?.asset?.storefront_id), assetId: activity?.asset?.id },
				}"
				:title="activity?.asset?.name"
			>
				<div class="flex items-center">
					<slot name="asset">
						<img
							v-if="props.showAsset"
							:src="$cdnify(activity?.asset.image, 120)"
							:alt="activity?.asset.name"
							class="ease-in-out w-auto rounded-xl h-20 duration-300 hover:scale-10"
						/>
					</slot>
					<div :class="{ 'ml-4': props.showAsset }">
						<h1 v-if="props.showAsset">
							<slot name="asset-name">{{ activity?.asset.name }}</slot>
						</h1>
						<div class="font-normal text-gray-500 text-sm">
							<slot name="asset-details">
								<p v-if="activity?.quantity">Quantity: {{ $format(activity?.quantity) }}</p>
							</slot>
						</div>
					</div>
				</div>
			</router-link>
			<div v-else class="flex items-center">
				<slot name="asset"></slot>
				<div :class="{ 'ml-4': props.showAsset }">
					<h1 v-if="props.showAsset">
						<slot name="asset-name"></slot>
					</h1>
					<div class="font-normal text-gray-500 text-sm">
						<slot name="asset-details"></slot>
					</div>
				</div>
			</div>
		</div>

		<div
			class="whitespace-nowrap p-2 text-sm text-gray-500 flex flex-col justify-center col-span-full @3xl:col-span-1"
		>
			<slot name="costs">
				<p v-if="activity?.total !== undefined || activity?.amount !== undefined" title="Price">
					<slot name="price">
						{{ $format(humanReadablePrice(activity?.total || activity?.amount)) }} {{ $token }}
					</slot>
				</p>

				<p v-if="activity?.fee !== undefined" class="text-gray-300" title="Fees">
					<slot name="fees"> {{ $format(humanReadablePrice(activity?.fee)) }} {{ $token }} </slot>
				</p>
			</slot>
		</div>

		<div class="whitespace-nowrap p-2 text-sm text-gray-500 flex items-center col-start-1 @3xl:col-start-auto">
			<slot name="user" v-if="activityUser(activity)"> @{{ activityUser(activity) }} </slot>
		</div>

		<div
			class="whitespace-nowrap p-2 text-sm text-gray-500 flex items-center justify-end col-start-[-1] @3xl:col-start-auto"
		>
			<slot name="timestamp">
				<p v-if="activity?.timestamp" :title="format(new Date(activity?.timestamp), 'Pp')">
					{{ formatDistanceToNow(new Date(activity?.timestamp)) }} ago
				</p>
			</slot>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { AssetActivity } from '@/types/Asset'
import { formatDistanceToNow, format } from 'date-fns'
import Action from './../Action.vue'
import { RouterLink } from 'vue-router'
import { useMarketStore } from '@/stores/MarketStore'
import { humanReadablePrice } from '@/util/currencyFormat'

const marketStore = useMarketStore()

const props = withDefaults(
	defineProps<{
		activity?: AssetActivity
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

function getSlug(id: string) {
	if (id === undefined) return ''
	return marketStore.byID[id]?.slug ?? ''
}

function activityUser(activity: AssetActivity) {
	if (activity?.seller) {
		return activity?.seller.username
	}

	if (activity?.sender) {
		return activity?.sender.username
	}

	if (activity?.recipient) {
		return activity?.recipient.username
	}

	return ''
}
</script>
