<template>
	<DefaultActivity :activity="props.activity">
		<template #asset-details> </template>
		<template #costs>
			<p title="High Bid Amount">
				High Bid: {{ $format(humanReadablePrice(activity?.new_bid_amount)) }} {{ $token }}
			</p>

			<p class="text-gray-300" title="Losing Bid Amount">
				Losing Bid: {{ $format(humanReadablePrice(activity?.old_bid_amount)) }} {{ $token }}
			</p>
		</template>
		<template #user>@{{ sellerOrRecipient(activity) }}</template>
	</DefaultActivity>
</template>

<script lang="ts" setup>
import { AssetActivity } from '@/types/Asset'
import DefaultActivity from './DefaultActivity.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

const props = withDefaults(
	defineProps<{
		activity?: AssetActivity
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

function sellerOrRecipient(activity: AssetActivity) {
	const { recipient, seller } = activity
	if (recipient) {
		return recipient.username
	} else if (seller) {
		return seller.username
	} else {
		return ''
	}
}
</script>
