import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'

import TosExit from '@/pages/TosExit.vue'
import OAuthCallback from '@/pages/OAuthCallback.vue'
import Index from './pages/Index.vue'
import AssetDetail from '@/pages/AssetDetail.vue'
import ListAsset from '@/pages/ListAsset.vue'
import TermsOfService from '@/pages/TermsOfService.vue'
import StoreCredit from './pages/StoreCredit.vue'
import StorefrontLayout from './pages/Storefront/StorefrontLayout.vue'
import StorefrontCollection from './pages/Storefront/StorefrontCollection.vue'
import StorefrontHeroLeaderBoard from './pages/Storefront/StorefrontHeroLeaderboard.vue'
import StorefrontActivity from './pages/Storefront/StorefrontActivity.vue'

import AccountLayout from './pages/Account/AccountLayout.vue'
import AccountCollection from './pages/Account/Collection.vue'
import AccountActivity from './pages/Account/AccountActivity.vue'
import AccountWallet from './pages/Account/AccountWallet.vue'
import AccountAuctions from './pages/Account/AccountAuctions.vue'
import AccountFavorites from './pages/Account/AccountFavorites.vue'
import AccountDrips from './pages/Account/AccountDrips.vue'
import AccountOffers from './pages/Account/AccountOffers.vue'

import StorefrontOffers from './pages/Storefront/StorefrontOffers.vue'
import BulkTransferPage from './pages/BulkTransferPage.vue'
import RedemptionsPage from './pages/RedemptionsPage.vue'

import { default as HomeTeamHeroesIndex } from './pages/HomeTeamHeroes/Index.vue'
import { default as HomeTeamHeroesView } from './pages/HomeTeamHeroes/View.vue'

const routes = [
	{
		path: '/',
		component: Index,
		name: 'home',
		meta: {
			title: 'Blokpax Market',
		},
	},
	{
		path: '/miles',
		component: StoreCredit,
		name: 'buy-store-credit',
	},
	{
		path: '/store/:slug',
		component: StorefrontLayout,
		children: [
			{
				path: '',
				component: StorefrontCollection,
				name: 'storefront',
			},
			{
				path: 'activity',
				component: StorefrontActivity,
				name: 'storefront:activity',
			},
			{
				path: 'leaderboard',
				component: StorefrontHeroLeaderBoard,
				name: 'storefront:leaderboard',
			},
			{
				path: 'offers',
				component: StorefrontOffers,
				name: 'storefront:offers',
			},
		],
		meta: {
			title: 'Blokpax Market',
		},
	},
	{
		path: '/store/:slug/:assetId(\\d+)',
		component: AssetDetail,
		name: 'asset',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/list/',
		component: ListAsset,
		name: 'list-asset',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/listing/:listingId/edit',
		component: ListAsset,
		name: 'edit-asset-listing',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/listing/:listingId',
		component: AssetDetail,
		name: 'purchase-listing',
	},
	{
		path: '/account',
		component: AccountLayout,
		children: [
			{
				path: '',
				component: AccountCollection,
				name: 'account',
			},
			{
				path: 'activity',
				component: AccountActivity,
				name: 'account:activity',
			},
			{
				path: 'wallet',
				component: AccountWallet,
				name: 'account:wallet',
			},
			{
				path: 'auctions',
				component: AccountAuctions,
				name: 'account:auctions',
			},
			{
				path: 'favorites',
				component: AccountFavorites,
				name: 'account:favorites',
			},
			{
				path: 'drips',
				component: AccountDrips,
				name: 'account:drips',
			},
			{
				path: 'offers',
				component: AccountOffers,
				name: 'account:offers',
			},
		],
	},
	{
		path: '/hometeamheroes',
		component: HomeTeamHeroesIndex,
		name: 'hometeamheroes',
	},
	{
		path: '/hometeamheroes/:drop',
		component: HomeTeamHeroesView,
		props: true,
	},
	{
		path: '/tos-exit',
		component: TosExit,
	},
	{
		path: '/auth/callback',
		component: OAuthCallback,
	},
	{
		path: '/terms-of-service',
		component: TermsOfService,
	},
	{
		path: '/transfer',
		name: 'bulk-transfer',
		component: BulkTransferPage,
	},
	{
		path: '/redeem',
		name: 'redemptions',
		component: RedemptionsPage,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (to.hash) {
					resolve({ el: to.hash })
				}

				resolve(savedPosition || { top: 0 })
			}, 500)
		})
	},
})

// This isn't really useful since we're not titling pages correctly.
// router.afterEach((to, from, failure) => {
// 	if (isNavigationFailure(failure)) {
// 		return
// 	}

// 	if (window.omnisend) {
// 		window.omnisend.push(['track', '$pageViewed'])
// 	}
// })

export default router
