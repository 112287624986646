<template>
	<account-layout-with-nav>
		<div class="my-6 md:mx-6 relative px-4">
			<ActivityTable :data-loader="loadActivity" />
		</div>
	</account-layout-with-nav>
</template>
<script lang="ts" setup>
import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import ActivityTable from '@/components/Activity/ActivityTable.vue'
import { useAccountStore } from '@/stores/AccountStore'

const accountStore = useAccountStore()

async function loadActivity(page: number | null = null): Promise<{ data: Object; meta: Object }> {
	return (await accountStore.storeActivityAccount(page)) as Promise<{ data: Object; meta: Object }>
}
</script>
