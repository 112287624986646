<template>
	<modal>
		<template v-if="!authStore.authenticated" #default>
			<ModalLogin @close="close" />
		</template>
		<template v-else #default>
			<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
				<div v-if="!state.txComplete">
					<div class="p-6 border-b flex justify-between">
						<p class="text-2xl font-bold">Accept Offer</p>
						<button
							v-if="!state.txInProgress"
							@click.stop="close"
							class="text-gray-500 absolute top-4 right-4 text-xl"
						>
							<i class="fas fa-close"></i>
						</button>
					</div>

					<div class="p-6 pb-0">
						<div class="p-4 border bg-slate-100 rounded-lg flex items-center">
							<div class="mt-1 mr-4 flex h-16 w-16 shrink-0 border rounded-lg">
								<img :src="$cdnify(props.asset.image, 128)" :alt="props.asset.name" class="mx-auto" />
							</div>
							<div>
								<p class="font-semibold">{{ props.asset.name }}</p>
								<p class="text-sm">{{ props.asset.storefront.name }}</p>
							</div>
						</div>
					</div>

					<section class="p-6 grow flex flex-col bg-white">
						<div class="flex space-x-6">
							<div class="w-1/2 relative mb-4">
								<label class="block text-md font-medium text-gray-700">Offer per Card</label>
								<div class="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
										placeholder="Amount"
										:value="
											$format(
												humanReadablePrice(BigInt(props.offer.offer_bpx_per_token).toString()),
											)
										"
										disabled
									/>
									<div class="absolute inset-y-0 right-1 flex items-center">
										<label for="price" class="sr-only">{{ $token }}</label>
										<span class="text-gray-400 sm:text-md p-2 font-bold bg-white">
											{{ $token }}
										</span>
									</div>
								</div>
							</div>
							<div class="w-1/2 relative">
								<label class="block text-md font-medium text-gray-700"> Quantity to sell </label>
								<div
									class="mt-1 relative rounded-md shadow-sm flex items-center text-md border-gray-800"
								>
									<button
										@click="subQuantity"
										class="absolute px-3 inset-y-0 left-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
										:disabled="offerState.quantity <= 0 || state.txInProgress"
									>
										<i class="fas fa-minus"></i>
									</button>
									<input
										type="text"
										class="block w-full pl-10 pr-10 sm:text-md text-center border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
										placeholder="# Quantity"
										v-model="offerState.quantity"
										:disabled="state.txInProgress"
									/>
									<button
										@click="addQuantity"
										class="absolute px-3 inset-y-0 right-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
										:disabled="
											state.txInProgress ||
											offerState.quantity >= availableCount ||
											offerState.quantity >= userOwned
										"
									>
										<i class="fas fa-plus"></i>
									</button>
								</div>
								<div class="grid grid-cols-2">
									<p class="text-xs mt-2 text-red-500">
										<span v-if="isNaN(offerState.quantity)">Please enter a valid quantity.</span>
										<span v-if="offerState.quantity > userOwned"
											>Cannot accept more than the amount owned.</span
										>
									</p>
									<p class="text-xs mt-2 ml-auto grow-0">Limit: {{ userOwned }}</p>
								</div>
							</div>
						</div>

						<div class="flex flex-col mt-4 border-t pt-4">
							<div class="ml-auto text-md mb-1">
								Subtotal:
								<span class="">
									{{
										isAcceptValid
											? $format(
													humanReadablePrice(
														BigInt(props.offer.offer_bpx_per_token) *
															BigInt(offerState.quantity >= 0 ? offerState.quantity : 0),
													),
											  )
											: '--'
									}}
									{{ $token }}
								</span>
							</div>
							<div v-if="transactionFee > 0" class="ml-auto text-md mb-1">
								Transaction fee:
								<span class="">
									{{
										$format(
											Math.max(
												1,
												Math.ceil(
													humanReadablePrice(
														BigInt(transactionFee) *
															BigInt(offerState.quantity >= 0 ? offerState.quantity : 0),
													),
												),
											),
										)
									}}
									{{ $token }}
								</span>
							</div>
							<p class="ml-auto font-bold text-xl mb-1">You will receive</p>
							<div class="rounded-lg flex flex-col items-end sm:flex-row sm:justify-end sm:items-center">
								<div class="ml-4 shrink-0">
									<p v-if="transactionFee > 0" class="text-lg">
										{{
											isAcceptValid
												? $format(
														humanReadablePrice(
															BigInt(props.offer.offer_bpx_per_token) *
																BigInt(
																	offerState.quantity >= 0 ? offerState.quantity : 0,
																),
														) -
															Math.max(
																1,
																Math.ceil(
																	humanReadablePrice(
																		BigInt(transactionFee) *
																			BigInt(
																				offerState.quantity >= 0
																					? offerState.quantity
																					: 0,
																			),
																	),
																),
															),
												  )
												: '--'
										}}
										{{ $token }}
									</p>
									<p v-else class="text-lg">
										{{
											isAcceptValid
												? $format(
														humanReadablePrice(
															BigInt(props.offer.offer_bpx_per_token) *
																BigInt(
																	offerState.quantity >= 0 ? offerState.quantity : 0,
																),
														),
												  )
												: '--'
										}}
										{{ $token }}
									</p>
								</div>
							</div>
						</div>
						<div v-if="state.txError" class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
							<p class="font text-base">
								<span
									class="inline-flex justify-center items-center px-2 bg-red-600 text-xs text-white aspect-square rounded-full leading-none"
								>
									<i class="fa fa-exclamation"></i>
								</span>
								Transaction Error
							</p>
							<p class="text-base">
								{{ state.txError }}
							</p>
						</div>
					</section>
				</div>

				<div v-else>
					<div class="p-6 border-b">
						<p class="text-2xl font-bold">You accepted an offer!</p>
					</div>
					<section class="p-6 flex flex-col items-center">
						<p v-if="transactionFee > 0" class="text-xl font-semibold">
							You have received
							{{
								isAcceptValid
									? $format(
											humanReadablePrice(
												BigInt(props.offer.offer_bpx_per_token) *
													BigInt(offerState.quantity >= 0 ? offerState.quantity : 0),
											) -
												Math.max(
													1,
													Math.ceil(
														humanReadablePrice(
															BigInt(transactionFee) *
																BigInt(
																	offerState.quantity >= 0 ? offerState.quantity : 0,
																),
														),
													),
												),
									  )
									: '--'
							}}
							{{ $token }}
						</p>
						<p v-else class="text-xl font-semibold">
							You have received
							{{
								$format(
									humanReadablePrice(
										BigInt(props.offer.offer_bpx_per_token) *
											BigInt(offerState.quantity >= 0 ? offerState.quantity : 0),
									),
								)
							}}
							{{ $token }}
						</p>
						<p class="text-gray-500 mt-2">
							in exchange for {{ offerState.quantity }} "{{ props.asset.name }}"
						</p>
					</section>
				</div>

				<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
					<button v-if="!state.txInProgress && !state.txComplete" class="text-gray-500" @click.stop="close">
						{{ state.txError || state.txComplete ? 'Close' : 'Cancel' }}
					</button>

					<button
						v-if="!state.txComplete"
						@click.stop="acceptOffer"
						class="btn-primary-lg enabled:bg-sky-600 enabled:hover:bg-sky-700 text-white space-x-1"
						:disabled="!isAcceptValid || state.txInProgress"
					>
						<span v-if="!state.txInProgress"> Accept Offer </span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
							Accepting Offer...
						</span>
					</button>

					<button v-if="state.txComplete" @click="close" class="btn-primary-lg">Done</button>
				</div>
			</div>
		</template>
	</modal>
</template>

<script lang="ts" setup>
import FeedbackModal from '@/components/modals/FeedbackModal.vue'
import { reactive, defineProps, computed, ComputedRef } from 'vue'
import { Offer } from '@/types/Storefront'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAuthStore } from '@/stores/AuthStore'
import { humanReadablePrice } from '@/util/currencyFormat'
import { Asset } from '@/types/Asset'
import { AxiosError } from 'axios'
import ModalLogin from '@/components/ModalLogin.vue'

const props = defineProps<{
	offer: Offer | null
	asset: Asset | null
}>()

const emit = defineEmits<{
	(event: 'close'): void
	(event: 'reload'): void
	(event: 'complete', offer: Offer): void
}>()
const isAcceptValid = computed(() => {
	return (
		offerState.quantity >= 1 &&
		offerState.quantity <= availableCount.value &&
		userOwned.value >= offerState.quantity
	)
})
const availableCount: ComputedRef<number> = computed(() => {
	return props.offer.total_offer_token_count - props.offer.tokens_fulfilled
})
const authStore = useAuthStore()
const storeStore = useStorefrontStore()
const state = reactive({
	txInProgress: false,
	txComplete: false,
	txError: null,
	tx: null,
})

const userOwned = computed(() => {
	if (!authStore.authenticated) {
		return 0
	}

	if (props.asset?.user_balance) {
		// props.asset.user_balance only in detail view
		return props.asset.user_balance
	} else {
		// props.asset.balance only in collection view
		return props.asset?.balance.available ?? 0
	}
})

const offerState = reactive({
	price: '',
	quantity: Math.min(availableCount.value, userOwned.value),
})

const initalState = computed(() => state.txError === false && state.txComplete === false)
const errorState = computed(() => state.txError === true && state.txComplete === false)
const successState = computed(() => state.txError === false && state.txComplete === true)
const remainingMiles = computed(() => {
	if (props.offer) {
		return (
			BigInt(props.offer.offer_bpx_per_token) *
			BigInt(props.offer.total_offer_token_count - props.offer.tokens_fulfilled)
		).toString()
	}
	return '0'
})

async function acceptOffer() {
	state.txInProgress = true
	if (state.tx) {
		state.txError = null
		state.tx = null
	}
	try {
		const acceptOffer: Offer = {
			...props.offer,
			token_count: offerState.quantity,
			asset_identifier: props.asset.asset_identifier,
		}

		const response = await storeStore.acceptStorefrontOffer(acceptOffer)

		if (response.success) {
			state.txComplete = true
			state.txInProgress = false
		} else {
			console.error('Failed to accept offer', response.message)
			state.txError = response.message
			state.tx = response
			state.txInProgress = false
		}
	} catch (e) {
		const err = e as AxiosError<Error>
		console.error('Something went wrong', e)
		state.txError = err.response?.data.message
		state.txInProgress = false
		state.tx = err
	}
}

const transactionFee = computed(() => storeStore.calculateFee(props.offer.offer_bpx_per_token))

function addQuantity() {
	if (isNaN(offerState.quantity)) {
		offerState.quantity = 1
	}
	if (offerState.quantity < availableCount.value) {
		offerState.quantity = Number(offerState.quantity) + 1
	}
}

function subQuantity() {
	if (isNaN(offerState.quantity)) {
		offerState.quantity = 0
	} else if (offerState.quantity <= 0) {
		offerState.quantity = 0
	} else {
		offerState.quantity = offerState.quantity - 1
	}
}

function close() {
	if (state.txError || state.txComplete) {
		emit('reload')
	} else {
		emit('close')
	}
}
</script>
